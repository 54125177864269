import React, { useState, useEffect, memo } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import reducer from './reducer';
import { useInjectReducer } from '../../../../common/internals/utils/injectReducer';
import saga from './saga';
import { useInjectSaga } from '../../../../common/internals/utils/injectSaga';
import { injectIntl } from 'react-intl';
import LocalisedLabel from '../../../../common/components/controls/LocalisedLabel';
import DropDown from '../../../../common/components/controls/DropDown';
import Button from '../../../../common/components/controls/Button';
import FileInputSingle from './FileInputSingle';
import FileValidator from './fileValidator';
import FileUploadReader from './fileUploadReader';
import FileSignatureValidator from './FileSignatureValidator';
import { FileUploadTranslationsMap } from './constants';
import * as Helpers from '../../../../common/internals/utils/helpers';
import  '../../../../common/app/accessibility-style.css';

import { DocumentSelected, FileSelected, FileValidationErrors, FileReadError, FileSignatureError, FileRead, UploadFile, FileUploadReset, FileUploadError, CloseModal } from './actions';
import {
    selectFileUploadRequestDomain,
    makeSelectLoading,
    makeSelectFileSelectionError,
    makeSelectDocumentTypeSelectionError,
    makeSelectFileUploadSuccessOrError,
    makeSelectDisableUI,
    makeSelectDocumentType,
    makeSelectuploadedFiles,
    makeSelectErrorFlag
} from './selectors';

export function FileUploadModal(props) {
    console.log(JSON.stringify(props));

    const fileSelectionError = props.fileSelectionError && props.fileSelectionError ? props.fileSelectionError : fileSelectionError;

    console.log(fileSelectionError);
    const { formatMessage } = props.intl;

    var popupTitle = formatMessage({ id: 'lblFileUpload' });

    const [hideStyle, Hide] = useState('');

    useInjectReducer({ key: 'fileUploadModal', reducer });
    useInjectSaga({ key: 'fileUploadModal', saga });

    useEffect(() => {
        console.log('useEffect');
        props.onDocumentSelectionChange(props.selectedDocument);
        FileUploadTranslationsMap.set('fileSignatureError', formatMessage({ id: 'fileSignatureError' }));
        FileUploadTranslationsMap.set('fileReadError', formatMessage({ id: 'fileReadError' }));
        FileUploadTranslationsMap.set('fileUploadSuccess', formatMessage({ id: 'fileUploadSuccess' }));
        FileUploadTranslationsMap.set('fileNotSelectedError', formatMessage({ id: 'fileNotSelectedError' }));
        FileUploadTranslationsMap.set('fileAlreadyUploadedError', formatMessage({ id: 'fileAlreadyUploadedError' }));
        FileUploadTranslationsMap.set('fileUploadError', formatMessage({ id: 'fileUploadError' }));        
        FileUploadTranslationsMap.set('invalidFileExtension', formatMessage({ id: 'invalidFileExtension' }));
        FileUploadTranslationsMap.set('fileUploadSizeExceeded', formatMessage({ id: 'fileUploadSizeExceeded' }));
        FileUploadTranslationsMap.set('fileNotSelectedError', formatMessage({ id: 'fileNotSelectedError' }));
        FileUploadTranslationsMap.set('fileUploadMaxFileCountExceeded', formatMessage({ id: 'fileUploadMaxFileCountExceeded' }));
        FileUploadTranslationsMap.set('lblFileInput', formatMessage({ id: 'lblFileInput' }));
        FileUploadTranslationsMap.set('TokenExpired', formatMessage({ id: 'errorMessages.tokenExpiredMsg' })); 
        FileUploadTranslationsMap.set('invalidFileName', formatMessage({ id: 'invalidFileName' }))
        

        console.log('FileUploadTranslationsMap size: ' + FileUploadTranslationsMap.size);       
        Helpers.KeepFocusInPopUp("fileUploadPopup");
        
    });

    const onFileSelectionChange = (evt) => {
        console.log("onFileSelectionChange");

        props.onFileSelected();


        var fileValidator = new FileValidator();
        //OnFileValidationErrors('');
        if (fileValidator.IsAValidFile(evt, props)) {
            var fileUploadReader = new FileUploadReader();
            fileUploadReader.ReadFile(evt, OnFileRead, OnFileReadError);
        }
        else {
            OnFileValidationErrors(fileValidator.Errors);
        }
    };

    const OnFileValidationErrors = (errors) => {
        console.log(errors);
        if (errors) {
            props.onFileValidationErrors(errors);
        }
    }

    const OnFileRead = (fileInfo) => {
        console.log(fileInfo);
        var fileSignatureValidator = new FileSignatureValidator();
        if (fileInfo &&
            (fileSignatureValidator.IsAValidFile(fileInfo))) {
            props.onFileRead(fileInfo);
        }
        else {
            OnFileSignatureError(fileInfo);
        }
    }

    const OnFileSignatureError = (fileInfo) => {
        console.log('The file selected for upload is not an allowed file. Invalid file signature detected.');
        props.onFileSignatureError(FileUploadTranslationsMap.get('fileSignatureError'));
    }

    const OnFileReadError = (error) => {
        if (error) {
            props.onFileReadError(FileUploadTranslationsMap.get('fileReadError'));
        }
    }

    const onFileInputSingleClicked = (evt) => {
        console.log("onFileInputSingleClicked");
    };

    const onUploadFileClicked = (evt) => {
        console.log('onUploadFileClicked');

        var fileHasAlreadyBeenUploaded = false;

        if(selectFileUploadRequestDomain && 
           selectFileUploadRequestDomain.fileUploadModal && 
           selectFileUploadRequestDomain.fileUploadModal.fileInfo &&
           selectFileUploadRequestDomain.fileUploadModal.uploadedFiles){
             if(selectFileUploadRequestDomain.fileUploadModal.uploadedFiles.has(selectFileUploadRequestDomain.fileUploadModal.fileInfo.name) === false){
                fileHasAlreadyBeenUploaded = true;
             }
        }
        
        if(fileHasAlreadyBeenUploaded === true){
            props.onFileUploadError(FileUploadTranslationsMap.get('fileAlreadyUploadedError'));
        }
        else{
            props.onUploadFileClicked();
        }        
    };

    const onCloseClicked = (evt) => {
        //props.oncloseClick();
        
        console.log('onCloseClicked');
        props.onCloseClicked();
        Hide({ display: 'none' });
        if (props.onClose != null) {
            props.onClose();
            
        }
        var attachment = document.getElementById('Attachment');
        attachment.focus();
        evt.preventDefault();
    };
    return (
        <div style={{ hideStyle }}>
            {/* https://partner.microsoft.com/en-us/common/ux/docs/portalsdoc/uicomponents/onedash/modals.html */}
            <div className="overlay"></div>
            <div id="fileUploadPopup"
                style={{ display: 'block', opacity: '1' }}
                className="modal" >
                <div role="dialog"  aria-roledescription="header" KeepFocusInPopUp="true"  title={popupTitle} className="modal-dialog">
                    <div className="modal-content">
            {/* <ModalPopUp id="fileUploadPopup" aria-describedby={popupTitle} aria-labeledby={popupTitle} aria-label={popupTitle} aria-modal="true" role="dialog"> */}
            {/* <div className="onedash-modal-wrapper icon-modal animateIn"> */}
                        <div className="form-group onedash-modal-content spacer-sm-bottom">
                    <div>
                        {/* <LocalisedLabel className="form-label" ariaLabel="File Upload" id="lblFileUpload" value="File Upload" /> */}
                                <h3 className="modal-title" id="fileuploaddialog" >{popupTitle}</h3>
                    </div>
                    <div>
                        <LocalisedLabel htmlFor="txtFileInput" className="form-label required" ariaLabel="Select a file" id="lblSelectAFile" value="Select a file" />
                    </div>
                    <div>
                        {/* <FileInputSingle ref="fileSelector" acceptFiles=".pdf,.doc,.docx" onChange={} onClick={} className="inputFile" /> */}
                        <FileInputSingle id="txtFileInput" className={props.disableUI ? "form-control disabled" : "form-control"} acceptFiles={props.acceptedFiles} onChange={(e) => onFileSelectionChange(e)} onClick={(e) => onFileInputSingleClicked(e)} className="form-control" value="" disabled={props.disableUI} />
                        { 
                            fileSelectionError &&
                            <div className="alert alert-error"  title="File Selection Error" role="alert" aria-label="File Selection Error" id="lblFileSelectionError" defaultValue="">{fileSelectionError}</div>
                        }
                    </div>
                    <div>
                        <LocalisedLabel htmlFor="ddlDocumentType" className="form-label required" ariaLabel="Document Type" id="lblDocumentType" value="Document type" />
                    </div>
                        <div>
                            <DropDown required="true" id="ddlDocumentType" className={props.disableUI ? "disabled" : ""} ariaLabel="Document Type" inputList={props.documentTypes} selectedValue={props.selectedDocument} onChange={(e) => props.onDocumentSelectionChange(e.target.value)} intl={props.intl} disabled={props.disableUI} />
                            {
                                props.documentTypeSelectionError &&
                                <div className="alert alert-error" title="Document Type Selection Error" role="alert" aria-label="Document Type Selection Error" id="lblDocumentTypeError" defaultValue="">{props.documentTypeSelectionError}</div>
                            } 
                        </div>
                    <div>
                        {
                            props.fileUploadSuccessOrError &&
                            <div className={props.isError ? "alert alert-error":"alert alert-info"} title="Upload Success Or Error" aria-label="Upload Success Or Error" role="alert" id="lblUploadSuccessOrError" defaultValue="">{props.fileUploadSuccessOrError}</div>
                        }
                    </div>
                    <div>
                                <div className="btn-group onedash-modal-buttons">
                                    <Button id="btnUpload" className={props.disableUI ? "btn disabled" : "btn c-button f-primary btn-primary"} type="button" ariaLabel="Upload" buttonTextId="btnUpload" intl={props.intl} onClick={(e) => onUploadFileClicked(e)} disabled={props.disableUI} />
                                    <Button id="btnClose" className={props.disableUI ? "btn disabled" : "btn c-button f-secondary"} type="button" ariaLabel="Close" buttonTextId="btnClose" intl={props.intl} onClick={(e) => onCloseClicked(e)} disabled={props.disableUI} />
                        </div>
                    </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* </ModalPopUp> */}
        </div>
    );
}

FileUploadModal.propTypes = {
    loading: PropTypes.bool,
    documentTypes: PropTypes.array.isRequired,
    selectedDocument: PropTypes.string.isRequired,
    acceptedFiles: PropTypes.string.isRequired,
    fileSelectionError: PropTypes.string,
    documentTypeSelectionError: PropTypes.string,
    fileUploadError: PropTypes.string,
    disableUI: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    attachment: PropTypes.array,
    isError: PropTypes.bool
};

const mapStateToProps = createStructuredSelector({
    loading: makeSelectLoading(),
    fileSelectionError: makeSelectFileSelectionError(),
    documentTypeSelectionError: makeSelectDocumentTypeSelectionError(),
    fileUploadSuccessOrError: makeSelectFileUploadSuccessOrError(),
    disableUI: makeSelectDisableUI(),
    selectDocument: makeSelectDocumentType(),
    attachment: makeSelectuploadedFiles(),
    isError: makeSelectErrorFlag()
});


function mapDispatchToProps(dispatch) {
    return {
        onDocumentSelectionChange: (document) => dispatch(DocumentSelected(document)),
        onFileSelected: () => dispatch(FileSelected()),
        onFileValidationErrors: (errors) => dispatch(FileValidationErrors(errors)),
        onFileRead: (fileInfo) => dispatch(FileRead(fileInfo)),
        onFileReadError: (error) => dispatch(FileReadError(error)),
        onFileSignatureError: (error) => dispatch(FileSignatureError(error)),
        onUploadFileClicked: () => dispatch(UploadFile()),
        onCloseClicked: () => dispatch(CloseModal()),
        onFileUploadError: (error) => dispatch(FileUploadError(error))
    }
}

//function OnShow() {
//    var p = document.getElementById("fileuploadpopup");
//    var focusableItems = p.querySelectorAll('input,a,button');
//    if (focusableItems.length > 0) {
//        setTimeout(() => focusableItems[0].focus(), 10);
//    }
//    p.addEventListener('keyup', function (e) {
//        FileUploadModal.tabfocus(e, p);
//    });
//}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(injectIntl(FileUploadModal));