import React, { memo } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Redirect } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import SubcfoSubmitHome from '../../../submit/subcfo/containers/subcfoSubmitHome';
import SearchCreditsSubCFO from '../../../search/subcfo/components/index';
import PrivacyStatement from '../privacy/index';
import CancelFutureBillingMenu from './CancelFutureBillingMenu';
import CancelFutureBillingSearch from '../../../search/cancelFutureBilling/components/index';
import CancelFutureBillingSearchHome from "../../../approval/approver/fbcApprover/home";
import { isFbcEnabled } from '../../internals/utils/featureFlag';
import CancelFutureBilligOpsHome from '../../../submit/operations/containers/cancelFutureBillingHome';
import MultiTenantHome from '../../../submit/operations/containers/multiTenantHome';
import MultiTenantMenu from './MultiTenantMenu';
import multiTenant from '../../../search/multiTenant/components/index';
import { isMtEnabledForUser } from "./MultiTenantMenu";
import UnAuthorized from "./../unauthroised";
import '../../app/accessibility-style.css';

export function FieldUserMenu(props) {

    const { formatMessage } = props.intl;
    const isFbcEnabledFlag = isFbcEnabled();
    const isMTEnabled = isMtEnabledForUser(props.userInfo);

    var lblCreateText = formatMessage({ id: "lblCreateText" });
    var lblSearchText = formatMessage({ id: "lblSearchText" });
    var lblSubCFORequestText = formatMessage({ id: "lblSubCFORequestText" });
    return (

        <div className="row remove-all-margin">
    <div className="col-md-5 col-lg-3 side-nav-wrap">
            <div className="onedash-container" data-bi-area="OneDashboardSide" role="navigation">
                <nav id="onedash-side-navigation" className="onedash-side-navigation mobileHide is-open" aria-label="menubar">

                        <div id="onedash-navigation-list" className="onedash-navigation-list">
                            <div id="onedash-top-menus">                               
                                <div id="partner-company-name3"
                                    className="onedash-accountpicker-account is-visible">
                                    <span className="activeAccount activeAccountTitle" aria-label={lblSubCFORequestText}>{lblSubCFORequestText}</span>
                                </div>

                                <div id="onedash-internaltools-menu-title-1" className="onedash-navigation-menu-title is-active">
                                    <ul role="menu">
                                        <li className="onedash-navigation-menu-item" role="none">
                                            <a href="/field/subcfo/create" id="lnkCreateRequest" role="menuitem" className="focusdiv">
                                                <div className="onedash-navigation-category" aria-label={lblSubCFORequestText+lblCreateText}>{lblCreateText}</div>
                                            </a>
                                        </li>
                                        <li className="onedash-navigation-menu-item" role="none">
                                            <a href="/field/subcfo/search" id="linkListSubmitterSearch" role="menuitem">
                                                <div className="onedash-navigation-category" aria-label={lblSubCFORequestText+lblSearchText}>{lblSearchText}</div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    {isFbcEnabledFlag === "True" ? <CancelFutureBillingMenu props={props}/> : null} 
                                </div>
                                <div>
                                    {isMTEnabled && <MultiTenantMenu />}
                                </div>
                            </div>
                        </div>
                        <div><PrivacyStatement /></div>
                    </nav>
                </div>

            </div>
            <div className="col-sm-24 col-md-19 col-lg-21 rightview">
                <Switch>
                    <Route path="/field/subcfo/search" component={SearchCreditsSubCFO} />
                    <Route path="/fbc/request:docId?/:requestId?/:requestStatus?" component={CancelFutureBillingSearchHome} />
                    <Route path="/fbc/search" component={CancelFutureBillingSearch} />
                    <Route path="/field/subcfo/create/:invoice?/:requestId?/:requestStatus?" component={SubcfoSubmitHome} />
                    <Route path="/fbc/create/:enrollmentNumber?/:systemPONumber?/:requestId?/:requestStatus?" render={() => <CancelFutureBilligOpsHome />} />
                    {isMTEnabled ?
                        <>
                            <Route path="/multitenant/create/:leadEnrollmentNumber?" render={() => <MultiTenantHome />} />
                            <Route path="/multitenant/search" component={multiTenant} />
                        </> :
                        <>
                            <Route path="/multitenant/create/:leadEnrollmentNumber?" render={() => <UnAuthorized />} />
                            <Route path="/multitenant/search" component={() => <UnAuthorized />} />
                        </>}
                    <Route path="/" render={() => (<Redirect to="/field/subcfo/create" push={true} />)} />
                </Switch>
            </div>
        </div>
    );
}

const withConnect = connect(null, null);
export default compose(withConnect, memo)(injectIntl(FieldUserMenu));

