import React from 'react';
import { PublicClientApplication } from '@azure/msal-browser';
import { setAuthType, setAccessToken } from '../../common/app/actions';

const logger = {
    loggerCallback: (logLevel, message, containsPii) => {
        console.log(message);
    },
    level: 'verbose',
    piiLoggingEnabled: false
};

const state = {
    launchApp: null,
    errorApp: null,
    idToken: null,
    accessToken: null,
    authType: null,
    email: '',
    name: ''
}

var appConfig = {
    instance: null,
    tenant: null,
    applicationId: null,
    cacheLocation: null,
    scopes: [],
    redirectUri: null,
    postLogoutRedirectUri: null,
    validateAuthority: null,
    accessTokenScope: null,
    liveLoginScope: null,
    msaTenantId: null,
    firstPartyEnabled: null,
    instrumentationKey: null
};

function authCallback(response) {
    console.log("authCallback");
    console.log(response);

    const accounts = window.msal.getAllAccounts();
    if (accounts.length > 0) {
        window.msal.setActiveAccount(accounts[0]);
    }

    if (response && response.tokenType == "access_token" && response.accessToken != null) {
        console.log('Login HOC: acquireTokenRedirect succeeded');
    }
}

function getDomain(sId) {
    var domainDictionary = window.vlcredits.envData.Services.domainDictionary;
    var keys = domainDictionary.split(",");

    var sidArray = sId.split("-");
    var sidArrayLength = sidArray.length;
    var indexValue = sidArrayLength - 1;
    var actualarray = sidArray.slice(0, indexValue);
    var actualSid = actualarray.join('-');
    var isAvailabe = keys.includes(actualSid);
    console.log("Is_available:", isAvailabe, actualSid);
    if (isAvailabe == true) {
        return actualSid;
    }
}

function getDomainUser(accountData) {
    var domainSid = "";
    var domain = "";
    var alias = "";
    if (accountData && accountData.idTokenClaims && accountData.idTokenClaims.onprem_sid) {
        domainSid = accountData.idTokenClaims.onprem_sid;
    } else {
        return domainSid;
    }
    domain = getDomain(domainSid);
    var upnData = accountData && accountData.idTokenClaims && accountData.idTokenClaims.upn ? accountData.idTokenClaims.upn : "";
    if (upnData) {
        var upnArray = upnData && upnData.split("@");
        alias = upnArray[0];
    }
    if (domain && alias) {
        return domain + "@" + alias;
    } else {
        return "";
    }
}

async function loginAndAcquireToken(successCallback, failureCallback) {
    const localMsalApp = window.msal;
    let account = localMsalApp.getActiveAccount();
    let accessTokenRequest = {
        scopes: account && account.idTokenClaims && account.idTokenClaims.tid === appConfig.msaTenantId ?
            (appConfig.firstPartyEnabled ? [appConfig.accessTokenScope] : [appConfig.liveLoginScope]) : [appConfig.accessTokenScope],
        extraQueryParameters: appConfig.firstPartyEnabled ? { 'msaoauth2': 'true' } : null
    }
    if (account && account.idTokenClaims && account.idTokenClaims.tid) {
        accessTokenRequest.authority = appConfig.instance + account.idTokenClaims.tid
    }
    if (account && account.idTokenClaims && account.idTokenClaims.tid === appConfig.msaTenantId) {
        state.authType = "msa";
    } else {
        const domainUservalue = getDomainUser(account);
        console.log("domain_value:", domainUservalue);
        if (domainUservalue) {
            state.authType = "aad";
        } else {
            state.authType = "aadupn";
        }
    }
    if (!account) {
        try {
            const response = await localMsalApp.acquireTokenSilent(accessTokenRequest);
            console.log('Login HOC: Obtained accessToken successfully');
            state.accessToken = response.accessToken;
            account = localMsalApp.getActiveAccount();
            state.idToken = account.idTokenClaims;
            state.email = account.username;
            state.name = account.name;
            if (state.launchApp) {
                state.launchApp();
            }
            if (successCallback) {
                successCallback();
            }
        } catch (error) {
            console.log('Login HOC: acquireTokenSilent error: ' + JSON.stringify(error));
            console.log('Login HOC: Trying loginRedirect');
            await localMsalApp.loginRedirect({
                scopes: appConfig.scopes,
                extraQueryParameters: appConfig.firstPartyEnabled ? { 'msaoauth2': 'true' } : null
            });
        }
    } else {
        try {
            const response = await localMsalApp.acquireTokenSilent(accessTokenRequest);
            console.log('Login HOC: Obtained accessToken successfully');
            state.accessToken = response.accessToken;
            if (state.launchApp) {
                state.launchApp();
            }
            if (successCallback) {
                successCallback();
            }
        } catch (error) {
            console.log('Login HOC: Silent accessToken failed');
            console.log('Login HOC: acquireTokenSilent error: ' + JSON.stringify(error));
            console.log('Login HOC: Trying acquireTokenRedirect');
            await localMsalApp.acquireTokenRedirect(accessTokenRequest);
        }
    }
}

const authentication = {
    initialize: (config) => {
        appConfig = config;
        const authority = config.instance && config.tenant ? config.instance + config.tenant : 'https://login.microsoftonline.com/common';
        const validateAuthority = (config.validateAuthority != null) ? config.validateAuthority : false;
        let scopes = config.scopes;
        if (!scopes || scopes.length === 0) {
            console.log('To obtain access tokens you must specify one or more scopes.');
        }
        state.scopes = scopes;

        const msalConfig = {
            auth: {
                clientId: config.applicationId,
                redirectUri: config.redirectUri,
                postLogoutRedirectUri: config.postLogoutRedirectUri,
                authority: authority,
                validateAuthority: validateAuthority
            },
            cache: {
                cacheLocation: config.cacheLocation,
                storeAuthStateInCookie: false
            },
            system: {
                loggerOptions: {
                    loggerCallback: logger.loggerCallback,
                    piiLoggingEnabled: logger.piiLoggingEnabled,
                    logLevel: logger.level
                }
            }
        }

        window.msal = new PublicClientApplication(msalConfig);
        window.msal.handleRedirectPromise().then(authCallback);
        if (!window.msal) {
            console.log('Login HOC: window.msal creation failed');
        }
    },
    run: (launchApp, errorApp) => {
        state.launchApp = launchApp
        if (errorApp)
            state.errorApp = errorApp;
        if (!window.msal.isCallback(window.location.hash) && window.parent === window && !window.opener) {
            loginAndAcquireToken();
        }
    },
    required: (WrappedComponent, renderLoading, ErrorComponent, store) => {

        return class EnhancedAppComponent extends React.Component {

            constructor(props) {
                super(props);
                this.state = {
                    signedIn: false,
                    error: false,
                };
            }

            componentWillMount() {
                loginAndAcquireToken(() => {
                    store && typeof store.dispatch === 'function' ? store.dispatch(setAuthType(state.authType)) : console.log("dispath is not a function");
                    store && typeof store.dispatch === 'function' ? store.dispatch(setAccessToken(state.accessToken)) : console.log("dispath is not a function");
                    this.setState({
                        ...this.state,
                        signedIn: true,
                        error: false
                    });
                }, () => {
                    this.setState({
                        ...this.state,
                        signedIn: false,
                        error: true
                    });
                });
            };

            render() {
                if (this.state.signedIn) {
                    return (<WrappedComponent key="appComponent" appinsightsKey={appConfig.instrumentationKey} {...this.props} />);
                };
                if (this.state.error) {
                    return (< ErrorComponent {
                        ...this.props
                    }
                    />);
                }
                return typeof renderLoading === 'function' ? renderLoading() : null;
            };
        }
    },
    signOut: () => {
        window.msal.logout()
    },
    getIdToken: () => {
        const account = window.msal.getActiveAccount();
        if (account) {
            return account.idTokenClaims;
        }
        return state.idToken;
    },
    getAccessToken: () => {
        return state.accessToken;
    },
    getAuthType: () => {
        return state.authType;
    },
    getEmail: () => {
        const account = window.msal.getActiveAccount();
        if (account) {
            return account.username;
        }
        return state.email;
    },
    getName: () => {
        const account = window.msal.getActiveAccount();
        if (account) {
            return account.name;
        }
        return state.name;
    }
}

export default authentication;