import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from 'react-intl';
import { connect } from "react-redux";
import { compose } from "redux";
import { pick } from "lodash";
import { createStructuredSelector } from "reselect";
import EnrollmentDetails from "../../../../common/components/enrollmentDetails/Loadable";
import LoadingIndicator from "../../../../common/components/LoadingIndicator";
import { useInjectReducer } from "../../../../common/internals/utils/injectReducer";
import { useInjectSaga } from "../../../../common/internals/utils/injectSaga";
import {
    makeSelectIsEnrollmentDetailsLoadedFromCreateRequest
} from "../../../../submit/common/components/multiTenantCreateRequest/selectors";
import MultiTenantCreateRequest from "../../../common/components/multiTenantCreateRequest";
import MultiTenantFetchRequest from "../../../common/components/multiTenantFetchRequest";
import MultiTenantApproverDetails from "../../../common/components/multiTenantApproverDetails";
import ReallocationDetails from "../reallocationDetails";
import FileUpload from "../../../common/components/fileUpload";
import { submitMultiTenantForm, submitMTApprovalRequest, changeRequiredCosmicCaseNumber, changeRequiredTrackingId } from './actions';
import messages from './messages';
import reducer from "./reducer";
import saga from "./saga";
import { makeSelectEnrollmentDetails, makeSelectIsReadOnly, makeSelectloading, makeSelectMultiTenantSubmitInitiated, makeSelectMultiTenantSubmitSuccess, makeSelectApprovalSuccess, makeSelectRequiredCosmicCaseNumber, makeSelectRequiredTrackingId, makeSelectMTAction, makeSelectTicket } from './selectors';
import { makeSelectRequestData } from "../../../common/components/multiTenantFetchRequest/selectors";
import * as Helpers from "../../../../common/internals/utils/helpers";
import Modal from "../../../../common/components/modal/modal";
import Input from '../../../../common/components/Input';
import AdditionalDetails from '../../../common/components/multiTenantAdditionalDetails';
import { injectIntl } from "react-intl";
import { makeSelectuploadedFiles } from "../../../common/components/fileUploadModal/selectors";
var _reviewCommentsErrorMessage = "Please enter review comments";

var _cosmicErrorMessage = <FormattedMessage {...messages.cosmicCaseErrorMessage} />;
var _trackingIdErrorMessage = <FormattedMessage {...messages.trackingIdErrorMessage} />;

function MultiTenantRequest({
    props,
    enrollmentDetailsProps,
    isEnrollmentDetailsLoaded,
    onSubmitForm,
    isReadOnly, multiTenantSubmitInitiated, multiTenantSubmitSuccess, loading,
    readOnlyMTData,
    onSubmitApprovalForm,
    onRejectAction,
    submitSuccess,
    uploadedFiles,
    action,
    onChangeCosmicCase,
    onChangeTrackingId,
    tickets,
    onBtnClick,
    crmId,
    ticketId
}) {
    useInjectSaga({ key: "multiTenantRequest", saga });
    useInjectReducer({ key: "multiTenantRequest", reducer });
    const {
        approverDetailsProps, approvalListProps
    } = readOnlyMTData ? sliceStateForComponents(readOnlyMTData) : {};

    const enableMTFileUploadVal = window.vlcredits.envData.Services.isMTFileUploadEnabled;
    const isMTFileUploadEnabled = enableMTFileUploadVal && enableMTFileUploadVal.toLowerCase() === "true" ? true : false;

    const enableMTApprovalVal = window.vlcredits.envData.Services.EnableMTApproval;
    const isMTApprovalEnabled = enableMTApprovalVal && enableMTApprovalVal.toLowerCase() === "true" ? true : false;

    const enableMTCRMIdVal = window.vlcredits.envData.Services.EnableMTCRMID;
    const isMTCRMIdEnabled = enableMTCRMIdVal && enableMTCRMIdVal.toLowerCase() === "true" ? true : false;

    var leadEnrollmentNumber = new URLSearchParams(window.location.search).get("leadEnrollmentNumber");
    var cosmicCaseNumber = enrollmentDetailsProps &&
        enrollmentDetailsProps.ticket &&
        enrollmentDetailsProps.ticket[0] ? enrollmentDetailsProps.ticket[0].ticketId : "";

    const readonly = readOnlyMTData && readOnlyMTData.isReadOnly;
    const isUserReadOnly = readOnlyMTData && readOnlyMTData.isUserReadOnly;
    const isApprovalReadonly = readonly || isUserReadOnly ? true : false;
    let userRole = window && window.userRole;
    var documentTypes = [{ "key": "4", "value": "Customer Acknowledgement Form" },
        { "key": "5", "value": "Tax Exemption Form" },
    { "key": "2", "value": "Others" }
    ];

   
     
    if (crmId === undefined) {
        crmId = '';
    }
   if (tickets && ticketId === undefined) {
       ticketId = '';
        let i = 0;
        for (i = 0; i < tickets.length - 1; i++) {
            if (tickets[i].ticketId)
            ticketId += tickets[i].ticketId;
            if (tickets[i].crmId )
                crmId += tickets[i].crmId;
            if (tickets[i+1].ticketId && tickets[i + 1].ticketId != '')
                ticketId += ",";
            if (tickets[i+1].crmId && tickets[i + 1].crmId != '')
                crmId += ",";
        }
        if (tickets[i].ticketId)
        ticketId += tickets[i].ticketId;
        if (tickets[i].crmId)
            crmId += tickets[i].crmId;
    }
    const disableApprovalButton = Helpers.isEmptyOrSpaces(crmId) || Helpers.isEmptyOrSpaces(ticketId);


    return (
        <div>

            {leadEnrollmentNumber ? <MultiTenantFetchRequest cosmicCaseNumberEnabled={!isMTCRMIdEnabled} cosmicCaseNumber={cosmicCaseNumber} submitterEmailEnabled={true} />
                : <MultiTenantCreateRequest />}
            <div
                className={
                    isEnrollmentDetailsLoaded || leadEnrollmentNumber
                        ? ""
                        : "hidden"
                }
            >

                <form onSubmit={onSubmitForm}>
                    <Modal
                        id="confirmationApprovalModal"
                        isOpen={submitSuccess}
                        headerMessage={action === "1" ? messages.modalRejectMessage : messages.modalApproveMessage}
                        closeMessage={messages.modalClose}
                        close={() => {
                            window.location.href = "/multitenant/search";
                        }}
                    />
                    <EnrollmentDetails {...enrollmentDetailsProps} isMultiTenant={true} />
                    <ReallocationDetails isReadOnly={isReadOnly} />
                    <div className={((multiTenantSubmitInitiated != undefined && multiTenantSubmitInitiated) || loading) ? "" : "hidden"}>
                        <div className="col-md-8  remove-all-padding">
                            <LoadingIndicator />
                        </div>
                    </div>

                    {isReadOnly && isMTApprovalEnabled ?
                        <AdditionalDetails
                            props={props}
                            approvalListProps={approvalListProps}
                            readOnlyMTData={readOnlyMTData}
                        /> : ""
                    }

                    {isMTFileUploadEnabled ?
                        <FileUpload isApprovalScreen={isReadOnly} docTypes={documentTypes} />
                        : ""

                        }

                    {isReadOnly && isMTApprovalEnabled ?
                        <div>
                            <MultiTenantApproverDetails
                                isApprovalReadonly={isApprovalReadonly}
                                approverDetailsProps={approverDetailsProps}
                            />
                            <div className="row spacer-xs-top">
                                <div className="col-sm-8 col-md-8 col-lg-6 col-xl-4">
                                    <Input id="cosmicCaseNumberRequired"
                                        autoComplete="off"
                                        value={ticketId || ''}
                                        label={messages.cosmicCaseNumber}  
                                        onChange={onChangeCosmicCase}
                                        placeholder="Separate multiple values with a ','"
                                        disabled={isApprovalReadonly}
                                        required={isMTCRMIdEnabled}
                                    />
                                </div>
                                <div className="col-sm-8 col-md-8 col-lg-6 col-xl-4">
                                    <Input id="mtRequestTrackingIdRequired"
                                        autoComplete="off"
                                        value={crmId || ''}
                                        label={messages.trackingId}  
                                        onChange={onChangeTrackingId}
                                        placeholder="Separate multiple values with a ','"
                                        disabled={isApprovalReadonly}
                                        required={isMTCRMIdEnabled}
                                    />
                                </div>
                            </div>
                            <div className="row col-sm-24 col-md-24 col-lg-24">
                                <div className="col-sm-24 col-md-24 col-lg-24">
                                    <div className="row spacer-xs-bottom">
                                        <div className={isApprovalReadonly === true ? "hidden" : ""}>
                                            <div className="btn-group">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary active"
                                                    id="btnfbcRequestApproveSubmit"
                                                    disabled={loading || disableApprovalButton/*|| submitLoading*/}
                                                    onClick={onSubmitApprovalForm}
                                                >
                                                    <FormattedMessage {...messages.btnApprove} />
                                                </button>
                                                <button
                                                    type="button"
                                                    id="rejectRequest"
                                                    className="btn btn-secondary"
                                                    onClick={onRejectAction}
                                                    disabled={loading || disableApprovalButton/*|| submitLoading*/}
                                                >
                                                    <FormattedMessage {...messages.btnReject} />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : ""
                    }



                    {!isReadOnly ?
                        <div>

                            <button
                                className={((!multiTenantSubmitInitiated) && !loading) ? "btn btn-default active" : "hidden"}
                                type="submit"
                                name="submitBtn"
                                disabled={!isEnrollmentDetailsLoaded || (userRole == 'VPU' && uploadedFiles == null)}
                                onClick={onBtnClick}
                                style={{
                                    marginLeft: "20px",
                                    background: "black",
                                    color: "white",
                                    top: "20px",
                                    bottom: "20px"
                                }}>
                                <FormattedMessage {...messages.submit} />
                            </button>
                        </div>
                        : ""}
                </form>
            </div>
        </div>
    );
};

function sliceStateForComponents(readOnlyMTData) {

    const approverDetailsProps =
        readOnlyMTData &&
            readOnlyMTData.leadEnrollmentNumber
            ? pick(readOnlyMTData, ["approverComment", "isReadOnly"])
            : {};
    const approvalListProps =
        readOnlyMTData.leadEnrollmentNumber
            ? readOnlyMTData.approverList
            : {};

    return {
        approverDetailsProps,
        approvalListProps
    };
}


function validateSubmitRequest(_action) {

    var txtApproverComments = document.getElementById("txtApproverComments");
    if (txtApproverComments) {
        txtApproverComments.setCustomValidity("");
        var reviewCommentsError = _reviewCommentsErrorMessage && _reviewCommentsErrorMessage.props && _reviewCommentsErrorMessage.props.defaultMessage;

        try {
            txtApproverComments.reportValidity();
        } catch (e) {
            //handles for IE browser
        }

        if (txtApproverComments && Helpers.isEmptyOrSpaces(txtApproverComments.value.trim())) {
            txtApproverComments.setCustomValidity(reviewCommentsError);
            try {
                txtApproverComments.reportValidity();
            } catch (e) {
                alert(reviewCommentsError)
            }
            return false;
        } else {
            txtApproverComments && txtApproverComments.setCustomValidity("");
            return true;
        }
    } else {
        return false;
    }


}


MultiTenantRequest.propTypes = {
    multiTenantSubmitInitiated: PropTypes.bool,
    multiTenantSubmitSuccess: PropTypes.bool,
    submitSuccess: PropTypes.bool,
};



const mapStateToProps = createStructuredSelector({
    enrollmentDetailsProps: makeSelectEnrollmentDetails(),
    isEnrollmentDetailsLoaded: makeSelectIsEnrollmentDetailsLoadedFromCreateRequest(),
    isReadOnly: makeSelectIsReadOnly(),
    multiTenantSubmitInitiated: makeSelectMultiTenantSubmitInitiated(),
    multiTenantSubmitSuccess: makeSelectMultiTenantSubmitSuccess(),
    loading: makeSelectloading(),
    readOnlyMTData: makeSelectRequestData(),
    uploadedFiles: makeSelectuploadedFiles(),
    submitSuccess: makeSelectApprovalSuccess(),
    action: makeSelectMTAction(),
    tickets: makeSelectTicket(),
    crmId: makeSelectRequiredTrackingId(),
    ticketId: makeSelectRequiredCosmicCaseNumber()
});

function mapDispatchToProps(dispatch) {
    return {
        onRejectAction: evt => {
            if (window && window.vlcredits.envData.Services.isRejectionReason == "true") {
                var _isValidRejection = false;
                var _dvErrorMessage = document.getElementById("dvRejectionReasonsErrorMessage");
                var _dvOtherErrorMessage = document.getElementById("dvOtherRejectionReasonsErrorMessage");
                var _txtOtherRejectionReason = document.getElementById("txtOtherRejectionReason");
                var _ddlReasons = document.getElementById("ddlRejectionReasons");
                var _dvSubmitErrorMessage = document.getElementById("dvSubmitErrorMessage");
                if (_ddlReasons) {
                    var _selectedReason = _ddlReasons.value;
                    if (_selectedReason && _selectedReason === "-1") {
                        _isValidRejection = false;

                        if (_dvErrorMessage)
                            _dvErrorMessage.style.display = "block";

                        if (_dvSubmitErrorMessage)
                            _dvSubmitErrorMessage.style.display = "none";

                    } else if (_selectedReason && _selectedReason === "Others") {
                        if (_txtOtherRejectionReason && _txtOtherRejectionReason.value.trim() === "") {
                            _isValidRejection = false;
                            if (_dvOtherErrorMessage)
                                _dvOtherErrorMessage.style.display = "block";

                        } else {
                            if (_txtOtherRejectionReason.value.trim().length < 50) {
                                _txtOtherRejectionReason.style.backgroundColor = "red";
                                _txtOtherRejectionReason.focus();
                                _isValidRejection = false;
                                if (_dvOtherErrorMessage)
                                    _dvOtherErrorMessage.style.display = "none";
                            } else {
                                _txtOtherRejectionReason.style.backgroundColor = "";
                                _isValidRejection = true;
                                if (_dvOtherErrorMessage)
                                    _dvOtherErrorMessage.style.display = "none";
                            }
                        }
                    } else {
                        _isValidRejection = true;
                        if (_dvErrorMessage)
                            _dvErrorMessage.style.display = "none";
                    }
                } else {
                    _isValidRejection = false;
                }
                var _txtApproverComments = document.getElementById("txtApproverComments");
                if (_isValidRejection && _txtApproverComments && _txtApproverComments.value.trim() === "") {
                    _isValidRejection.style.className = "alert alert-error";
                    _isValidRejection = false;
                }
                if (_isValidRejection) {
                    evt.target.disabled = true;
                    window.scrollTo(0, 0);
                    dispatch(submitMTApprovalRequest("1"));
                }
            } else {
                if (evt !== undefined && evt.preventDefault) evt.preventDefault();
                {
                    var isValid = validateSubmitRequest(1);
                    if (isValid) {
                        evt.target.disabled = true;
                        dispatch(submitMTApprovalRequest("1"));
                        window.scrollTo(0, 0);
                    }

                }

            }
        },

        onSubmitApprovalForm: evt => {
            if (evt !== undefined && evt.preventDefault) evt.preventDefault();
            {
                if (window && window.vlcredits.envData.Services.isRejectionReason == "true") {
                    var _ddlReasons = document.getElementById("ddlRejectionReasons");
                    var _dvSubmitErrorMessage = document.getElementById("dvSubmitErrorMessage");
                    var _txtApproverComments = document.getElementById("txtApproverComments");
                    var _dvApproverErrorMessage = document.getElementById("dvtxtApproverCommentsErrorMessage");
                    _dvApproverErrorMessage.style.display = "none";
                    _dvSubmitErrorMessage.style.display = "none";
                    if (_ddlReasons) {
                        var _selectedReason = _ddlReasons.value;
                        if (_selectedReason && _selectedReason === "-1" && _txtApproverComments && _txtApproverComments.value.trim().length > 0) {
                            dispatch(submitMTApprovalRequest("0"));
                            window.scrollTo(0, 0);
                        } else if (_selectedReason && _selectedReason === "-1" && _txtApproverComments && _txtApproverComments.value.trim() === "") {
                            _dvApproverErrorMessage.style.display = "block";
                        } else {
                            _dvSubmitErrorMessage.style.display = "block";
                            _ddlReasons.focus();
                        }
                    }
                } else {
                    var isValid = validateSubmitRequest(0);
                    if (isValid) {
                        evt.target.disabled = true;
                        dispatch(submitMTApprovalRequest("0"));
                        window.scrollTo(0, 0);
                    }
                }
            }
        },
        onBtnClick: evt => {
            if (evt) {
                if (evt.preventDefault) evt.preventDefault();
                const role = window.userRole ? window.userRole : null;
                const enableMTCRMIdVal = window.vlcredits.envData.Services.EnableMTCRMID;
                const isMTCRMIdEnabled = enableMTCRMIdVal && enableMTCRMIdVal.toLowerCase() === "true" ? true : false;
                if (role != 'VPU' && isMTCRMIdEnabled) {
                    var _cosmicError = _cosmicErrorMessage && _cosmicErrorMessage.props && _cosmicErrorMessage.props.defaultMessage;
                    var _trackingIdError = _trackingIdErrorMessage && _trackingIdErrorMessage.props && _trackingIdErrorMessage.props.defaultMessage;
                    var _txtCosmicCaseNumber = document.getElementById("cosmicCaseNumber");
                    var _txtTrackingId = document.getElementById("trackingId");
                    if (Helpers.isEmptyOrSpaces(_txtCosmicCaseNumber.value)) {
                        _txtCosmicCaseNumber.setCustomValidity(_cosmicError);
                        try {
                            _txtCosmicCaseNumber.reportValidity();
                        } catch (e) {
                            alert(_cosmicError);
                        }
                    }
                    if (Helpers.isEmptyOrSpaces(_txtTrackingId.value)) {
                        _txtTrackingId.setCustomValidity(_trackingIdError);
                        try {
                            _txtTrackingId.reportValidity();
                        } catch (e) {
                            alert(_trackingIdError);
                        }
                    }
                }

                if (document.activeElement.getAttribute('name') === "submitBtn") {
                    dispatch(submitMultiTenantForm());
                }
                    window.scrollTo(0, 0);



            }
        },
        onSubmitForm: evt => {
            if (evt) {
                if (evt.preventDefault) evt.preventDefault();
               
            }
        },
         onChangeCosmicCase: evt => {
            if (evt !== undefined && evt.preventDefault) evt.preventDefault();
            dispatch(changeRequiredCosmicCaseNumber(evt.target.value));
        },
        onChangeTrackingId: evt => {
            if (evt !== undefined && evt.preventDefault) evt.preventDefault();
            dispatch(changeRequiredTrackingId(evt.target.value));
        },



             

    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)((MultiTenantRequest));
