export const responseMap = {
    "Currency": {
        "CurrencyCode": "CurrencyCode",
        "CurrencyName": "CurrencyName"
    },
    "PurchaseOrderType": {
        "PurchaseOrderTypeCode": "PurchaseOrderTypeCode",
        "PurchaseOrderTypeName": "PurchaseOrderTypeName"
    },
    "Program": {
        "ProgramCode": "ProgramCode",
        "ProgramName": "ProgramName"
    },
    "Country": {
        "CountryCode": "CountryCode",
        "CountryName": "CountryName"
    },
    "BillingOption": {
        "BillingOptionCode": "BillingOptionCode",
        "BillingOptionName": "BillingOptionName"
    },
    "GetMTReturn": {
        "billingDocumentNbr": "leadEnrollmentNumber",
        "ticket": "ticket",
        "submitUserEmail": "submitUserEmail",
        "isSUB": "isSUB",
        "tenants": "tenants",
        "leadAgreementName": "leadAgreementName",
        "multiTenantPODetails": "multiTenantPODetails",
        "programCode": "programCode",
        "masterAgreementNumber": "masterAgmNumber",
        "billToCountryCode":"billToCountryCode",
        "salesLocationCode":"salesLocationCode",
        "pricingCurrency":"pricingCurrency",
        "adjustedPOStatus":"adjustedPOStatus",
        "errors": "errors",
        "requestStatusCode": "requestStatusCode",
        "cancellationReason": "cancellationReason",
        "isReadOnly": "isReadOnly",
        "isUserReadOnly": "isUserReadOnly",
        "attachment": "attachment",
        "workflowList": "workflowList"
    },
    "GetReturn": {
        "purchaseOrderId": "purchaseOrderId",
        "billingDocumentNbr": "invoiceNumber",
        "invoiceDate": "invoiceDate",
        "invoiceTotalAmount": "invoiceAmount",
        "pricingCurrencyCode": "currency",
        "invoiceTotalAmountUSD": "invoiceAmountUSD",
        "purchaseOrderNumber": "PONumber",
        "systemPONumber": "SystemPONumber", //for FBC, roc requires to have systemponumber displayed in UI.
        "purchaseOrderTypeCode": "purchaseOrderTypeCode",
        "agreementNumber": "enrollmentNumber",
        "programCode": "programCode",
        "masterAgreementNumber": "masterAgmNumber",
        "salesLocationCode": "salesLocationCode",//need to validate either salesLocationCode or CountryCode
        "endCustomerPurchaseOrderNumber": "customerPCN", //done
        "billToPCN": "directPartnerPCN",//done
        "indirectCustomerNumber": "resellerPCN",
        "endCustomerName": "customerName",//done
        "billToName": "partnerName",//done
        "resellerName": "resellerName",
        "returnReasonCode": "returnReason",
        "isFullCredit": "isFullCredit",
        "IsBdApproved": "isBdApproved",//boolean value
        "handlingFee": "handlingFeePercentage",//will be integer
        "netAmountUSD": "netCreditUSD",
        "netAmount": "netAmount",
        "notes": "notes",
        "purchaseOrderLineItemCollection": "lineItemDetails",
        "multipleInvoiceList":"multipleInvoiceList",
        "rebillPOList": "rebillInvoicePO",
        "workflowList": "workflowList",
        "tickets": "tickets",
        "attachement": "documents",
        "empowermentIds": "empowermentIdCSV",
        "cosmicCaseNumber": "cosmicCaseNumber",
        "isOptOut": "isOptOut",
        "returnsIdentifier": "requestId",
        "notaFiscal": "localInvoiceNumber",
        "approverComment": "approverComment",
        "approverCallout": "approverCallout",
        "api_partnerEmail": "partnerEmail",//need to remove from UI
        "submitUserEmail": "customerEmail",//need to remove from UI
        "api_resellerEmail": "resellerEmail",//need to remove from UI
        "isReadOnly":"isReadOnly",
        "isUserReadOnly":"isUserReadOnly",
        "billToCountryCode":"billToCountryCode",
        "attachment": "attachment",
        "requestStatusCode": "requestStatusCode",
        "licenseAge": "licenseAge",
        "userRole":"userRole",
        "isDirectAgreement":"isDirectAgreement",
        "rejectionReason": "rejectionReason",
        "cancellationReason": "cancellationReason",       
        "isCFOReadOnly":"isCFOReadOnly",
        "submitUserEmail": "submitUserEmail",
        "isSUB": "isSUB",
        "isAutoSubmit": "isAutoSubmit",
        "operationsCenterCode": "operationsCenterCode",
        "alternateROC": "alternateROC",
        "hasMultipleRequestCurrency": "hasMultipleRequestCurrency",
        "hasMultipleRebillCurrency": "hasMultipleRebillCurrency",
        "multiTenantPODetails":"multiTenantPODetails"
    },
    "RebillPO": {
        "identifier": "identifier",
        "amount": "amount",
        "amountUSD": "amountUSD",
        "poInvoiceDate": "poInvoiceDate",
        "currency": "currency",
        "rebillPOtype": "rebillPOtype",
        "isEligible": "isEligible",
        "lineItems": "lineItems",
        "poStatusCode":"poStatusCode",
        "programCode":"programCode",
        "selectedAmount":"rebillPOAmount",
        "selectedAmountUSD": "rebillPOAmountUSD"

    },
    "ApproverList": {
        "workflow": "workflow",
        "approverLevel": "approverLevel",
        "approvedBy": "approvedBy",
        "approveDate": "approveDate",
        "status": "status",
        "validUntill": "validUntill",
        "approverComments": "approverComments",
        "rejectionReason": "rejectionReason",
        "cancellationReason": "cancellationReason"
    },
    "CreditsList":
    {
        "tickets": null,
        "purchaseOrderId": 0,
        "masterAgreementNumber": null,
        "isFullCredit": false,
        "invoiceTotalAmount": 0,
        "invoiceTotalAmountUSD": 0,
        "netAmount": null,
        "netAmountUSD": null,
        "billToPCN": null,
        "billToName": null,
        "endCustomerPurchaseOrderNumber": null,
        "endCustomerName": null,
        "indirectCustomerNumber": null,
        "resellerName": null,
        "directCustomerBillToNumber": null,
        "billOnTime": null,
        "externalIdentifier": null,
        "purchaseOrderNumber": null,
        "agreementNumber": null,
        "transactionSetPurposeCode": null,
        "statusCode": null,
        "agreementTypeCode": null,
        "usagePeriodDate": null,
        "programCode": null,
        "purchaseOrderTypeCode": null,
        "purchaseOrderLineItemCollection": null,
        "pricingCurrencyCode": null,
        "purchaseOrderDate": "0001-01-01T00:00:00",
        "source": null,
        "entitySourceSystem": null,
        "customerTypeCode": null,
        "salesLocationCode": null,
        "operationsCenterCode": "EOC",
        "rebillPOList": null,
        "attachements": null,
        "requestStatusCode": "SAIA",
        "rmaId": null,
        "rmaNbr": null,
        "approverList": null,
        "returnsIdentifier": "28a6a88f-80d9-4992-ac82-ba75c34f7375",
        "isReadOnly": null,
        "requestApprovalDate": null,
        "approvalStatusCode": null,
        "licenseAge": 0,
        "returnReasonCode": null,
        "submitUserId": 123,
        "submitUserLCID": null,
        "submitUserEmail": null,
        "requestEntryDate": null,
        "requestSubmitDate": null,
        "billingDocumentNbr": "9831778460",
        "pKey": "vl_returnsorder_search",
        "id": "9831778460",
        "_etag": "07008a44-0000-0300-0000-5ce712630000",
        "rejectionReason": null,
        "cancellationReason": null,
        "isAutoSubmit":null

    },
    "lineItemDetails": [{
        "externalIdentifier": "externalIdentifier",
        "poliStatus": "poliStatus",
        "productFamilyCode": "productFamilyCode",
        "productFamilyName": "productFamilyName",
        "partNumber": "partNumber",
        "programOfferingCode": "programOfferingCode",
        "programOfferingName": "programOfferingName",
        "usageCountryCode": "usageCountryCode",
        "billingOptionCode": "billingOptionCode",
        "productTypeCode": "productTypeCode",
        "salesOrderLineItemId": "salesOrderLineItemId",
        "salesOrderId": "salesOrderId",
        "poLineItemId": "poLineItemId",
        "revenueTransactionTypeCode": "revenueTransactionTypeCode",
        "soliStatus": "soliStatus",
        "externalStatus": "externalStatus",
        "quantity": "quantity",
        "usedPrice": "usedPrice",
        "billOnDate": "billOnDate",
        "coverageStartDate": "coverageStartDate",
        "coverageEndDate": "coverageEndDate",
        "extendedAmount": "extendedAmount",
        "invoiceNumber": "invoiceNumber",
        "invoiceTotalAmount": "invoiceTotalAmount",
        "billingMultiplierPrice": "billingMultiplierPrice",
        "usagePeriodDate": "usagePeriodDate",
        "billingTypeCode": "billingTypeCode",
        "invoiceDate": "invoiceDate",
        "purchaseUnitCode": "purchaseUnitCode",
        "originalPurchaseOrderNumber": "originalPurchaseOrderNumber",
        "endCustomrPurchaseOrderNumber": "endCustomrPurchaseOrderNumber",
        "createdDate": "createdDate",
        "billingMultipler": "billingMultipler",
        "specialPricingAppliedFlag": "specialPricingAppliedFlag",
        "comments": "comments",
        "adjustedQuantity": "adjustedQuantity",
        "adjustedPrice": "adjustedPrice",
        "productDescription": "productDescription",
        "isAdjusted": "isAdjusted"
    }
    ],
    "multipleInvoiceList": [{
        "exchangeRate": "exchangeRate ",
        "externalIdentifier": "externalIdentifier",
        "invoiceDate": "invoiceDate",
        "invoiceNumber": "invoiceNumber",
        "invoiceTotalAmount": "invoiceTotalAmount",
        "invoiceTotalAmountUSD": "invoiceTotalAmountUSD",
        "isSelected": "isSelected",
        "localInvoiceNumber": "localInvoiceNumber"
    }],
    "multiTenantPODetails": [{
        "createdData": "createdData",
        "externalIdentifier":"externalIdentifier",
        "poliDetails":"poliDetails",
        "purchaseOrderId":"purchaseOrderId",
        "purchaseOrderNumber":"purchaseOrderNumber",
        "purchaseOrderTypeCode":"purchaseOrderTypeCode",
        "sourceTenant":"sourceTenant",
        "systemPONumber":"systemPONumber"
        }
    ],
    "poliDetails": [{
        "POLineItemID":"POLineItemID",
        "coveragePeriodEndDate":"coveragePeriodEndDate",
        "coveragePeriodStartDate":"coveragePeriodStartDate",
        "createdDate":"createdDate",
        "destinationTenant":"destinationTenant",
        "partNumber":"partNumber",
        "quantity":"quantity"
        }
    ],
    "destinationTenant": [{
        "newPONumber":"newPONumber",
        "quantity":"quantity",
        "tenantId":"tenantId",
        "tenantName":"tenantName"
        }
    ]
}

