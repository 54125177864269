import {
    DOCUMENT_SELECTED,
    FILE_SELECTED,
    FILE_VALIDATION_ERRORS,
    FILE_READ_ERROR,
    FILE_SIGNATURE_ERROR,
    FILE_UPLOAD_ERROR,
    FILE_READ,
    UPLOAD_FILE,
    FILE_UPLOAD_SUCCESS,
    FILE_UPLOAD_RESET,
    FILE_REMOVED,
    MODAL_OPEN,
    MODAL_CLOSED,
    DOWNLOAD_FILE,
    BLOB_FILE,
    FILE_REMOVED_SUCCESS,
    FILE_REMOVED_ERROR,
    DOWNLOAF_FILE_SUCCESS,
    DOWNLOAF_FILE_ERROR
} from './constants';

export function DocumentSelected(document){
    console.log("DocumentSelected action called.");
    return {
        type: DOCUMENT_SELECTED, document
    }    
}

export function FileSelected(){
    console.log('FileSelected action called.');
    return {
        type: FILE_SELECTED
    }
}

export function FileValidationErrors(errors) {
    console.log("FileValidationErrors action called.");
    return {
        type: FILE_VALIDATION_ERRORS, errors
    }
}

export function FileReadError(error) {
    console.log("FileReadError action called.");
    return {
        type: FILE_READ_ERROR, error
    }
}

export function FileSignatureError(error) {
    console.log("FileSignatureError action called.");
    return {
        type: FILE_SIGNATURE_ERROR, error
    }
}

export function FileUploadError(error) {
    console.log("FileUploadError action called.");
    console.log(error);
    return {
        type: FILE_UPLOAD_ERROR, error
    }
}

export function FileRead(fileInfo) {
    console.log("FileRead action called.");
    return {
        type: FILE_READ, fileInfo
    }
}

export function UploadFile() {
    console.log("UploadFile action called.");
    return {
        type: UPLOAD_FILE
    }
}

export function FileUploadSuccess(success) {
    console.log("FileUploadSuccess action called.");
    return {
        type: FILE_UPLOAD_SUCCESS, success
    }
}

export function FileUploadReset(){
    console.log("Reset action called.");
    return {
        type: FILE_UPLOAD_RESET
    }
}

export function AttachmentFileRemoved(fileIdentifier) {
    console.log("File Removed action called.");
    return {
        type: FILE_REMOVED, fileIdentifier
    }
}

export function DowloadAttachedFile(fileIdentifier) {
    console.log("File DowloadAttachedFile action called.");
    return {
        type: DOWNLOAD_FILE,
        payload: fileIdentifier
    }
}

export function DOwnloadFileSuccess() {
    return {
        type: DOWNLOAF_FILE_SUCCESS
    }
}

export function DOwnloadFileError(error) {
    return {
        type: DOWNLOAF_FILE_ERROR, error
    }
}

export function OpenModal() {
    console.log("OpenModal action called.");
    return {
        type: MODAL_OPEN
    }
}

export function CloseModal() {
    console.log("CloseModal action called.");
    return {
        type: MODAL_CLOSED
    }
}

export function DeleteFileSuccess(fileIdentifier) {
    console.log("DeleteFileSuccess action called");
    return {
        type: FILE_REMOVED_SUCCESS,
        fileIdentifier
    }
}

export function DeleteFileError(fileName) {
    console.log("DeleteFileError action called");
    return {
        type: FILE_REMOVED_ERROR,
        fileName
    }
}



