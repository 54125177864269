import React, { memo } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Redirect } from 'react-router'
import { Switch, Route } from 'react-router-dom';
import SearchCreditsOps  from '../../../search/operations/components/index';
import PartnerSubmitHome from '../../../submit/partners/containers/partnerSubmitHome';
import FBCPartnerSubmitHome from '../../../submit/partners/containers/fbcPartnerSubmitHome';
import PrivacyStatement from '../privacy/index';
import CancelFutureBillingMenu from './CancelFutureBillingMenu';
import CancelFutureBillingSearch from '../../../search/cancelFutureBilling/components/index';
import CancelFutureBillingSearchHome from "../../../approval/approver/fbcApprover/home";
import { isFbcEnabled } from '../../internals/utils/featureFlag';
import MultiTenantHome from '../../../submit/operations/containers/multiTenantHome';
import MultiTenantMenu from './MultiTenantMenu';
import multiTenant from '../../../search/multiTenant/components/index';
import { isMtEnabledForUser } from "./MultiTenantMenu";
import UnAuthorized from "./../unauthroised";
import { createStructuredSelector } from 'reselect';
import { makeSelectUserInfo } from '../../app/selectors';
import '../../app/accessibility-style.css';


export function PartnerUserMenu(props) {

    const { formatMessage } = props.intl;
    const isFbcEnabledFlag = isFbcEnabled();
    var lblCreateRequestText = formatMessage({ id: "lblCreateRequestText" })
    var lblCreateText = formatMessage({ id: "lblCreateText" })
    var lblSearchText = formatMessage({ id: "lblSearchText" })
    var isMTEnabled = isMtEnabledForUser(props.userInfo);


    return (

        <div className="row remove-all-margin">
            <div className="col-md-5 col-lg-3 side-nav-wrap">
                <div className="onedash-container" data-bi-area="OneDashboardSide" role="navigation">
                    <nav id="onedash-side-navigation" className="onedash-side-navigation mobileHide is-open" aria-label="menubar">

                        <div id="onedash-navigation-list" className="onedash-navigation-list">
                            <div id="onedash-top-menus">
                                <input type="hidden" id="applicationMenuDropDownCommon" data-accountid="0" name="common-accountid" />
                                <div id="partner-company-name"
                                    className="onedash-accountpicker-account is-visible">
                                    <span className="activeAccount activeAccountTitle" aria-label={lblCreateRequestText}>{lblCreateRequestText}</span>
                                </div>
                                <div id="onedash-internaltools-menu-title" className="onedash-navigation-menu-title is-active">
                                    <ul role="menu">
                                        <li className="onedash-navigation-menu-item" role="none">
                                            <a href="partner/create" id="create" role="menuitem" className="focusdiv">
                                                <div className="onedash-navigation-category" aria-label={lblCreateRequestText + lblCreateText} >{lblCreateText}</div>
                                            </a>
                                        </li>
                                        <li className="onedash-navigation-menu-item" role="none">
                                            <a href="partner/search" id="list" role="menuitem">
                                                <div className="onedash-navigation-category" aria-label={lblCreateRequestText + lblSearchText}>{lblSearchText}</div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    {isFbcEnabledFlag === "True" ? <CancelFutureBillingMenu /> : null}
                                </div>
                                <div>
                                   { isMTEnabled && <MultiTenantMenu />}
                                </div>
                            </div>
                        </div>
                        <div><PrivacyStatement /></div>
                    </nav>
                </div>

            </div>
            <div className="col-sm-24 col-md-19 col-lg-21 rightview">
                <Switch>
                    <Route path="/partner/create" component={PartnerSubmitHome} />
                    <Route path="/partner/search" component={SearchCreditsOps} />
                    <Route path="/partner/request:license?/:requestId?/:requestStatus?" component={PartnerSubmitHome} />

                    <Route path="/partner/fbc/create" component={FBCPartnerSubmitHome} />
                    <Route path="/partner/fbc/search" component={CancelFutureBillingSearch} />
                    <Route path="/partner/fbc/request:docId?/:requestId?/:requestStatus?" component={CancelFutureBillingSearchHome} />

                    <Route path="/" exact>
                        <Redirect to="/partner/create" />
                    </Route>

                    {isMTEnabled ? 
                        <>
                            <Route path="/partner/multitenant/create/:leadEnrollmentNumber?" render={() => <MultiTenantHome />} />
                            <Route path="/partner/multitenant/search" component={multiTenant} />
                        </> :
                        <>
                            <Route path="/partner/multitenant/create/:leadEnrollmentNumber?" render={() => <UnAuthorized />} />
                            <Route path="/partner/multitenant/search" component={() => <UnAuthorized/>} />
                        </>}
                    

                </Switch>
            </div>
        </div>
    );
}

const mapStateToProps = createStructuredSelector(
    {userInfo: makeSelectUserInfo()}
);

const withConnect = connect(mapStateToProps, null);
export default compose(withConnect, memo)(injectIntl(PartnerUserMenu));
