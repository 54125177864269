//#region  import section

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import { pick } from "lodash";
import { injectIntl, FormattedMessage } from "react-intl";

import reducer from "./reducer";
import saga from "./saga";
import {
    makeSelectFbcAction,
    makeSelectSelectFbcApprovalSuccess,
    makeSelectSelectFbcApprovalLoading,
} from "./selectors";
import {
    submitFbcApprovalRequest,
    approvalFbcReject,
    proceedFbcApprovalRequest,
    closeDialog,
} from "./actions";
import messages from "./messages";

import * as Helpers from "../../../../common/internals/utils/helpers";
import { useInjectSaga } from "../../../../common/internals/utils/injectSaga";
import { useInjectReducer } from "../../../../common/internals/utils/injectReducer";
import EnrollmentDetails from "../../../../common/components/enrollmentDetails/Loadable";
import ContactDetails from "../../../../common/components/contactDetails/Loadable";
import AdditionalDetails from "../additionalDetails/Loadable";
import ApproverDetails from "../approverDetails/Loadable";
import LineItemDetails from "../lineItemDetails/Loadable";
import RequestHeader from "../requestHeader/Loadable";
import {
    makeSelectInvoice,
    makeSelectLoading,
} from "../../../../approval/approver/containers/createRequest/selectors";
import CreateRequest from "../../../../approval/approver/containers/createRequest/Loadable";
import Modal from "../../../../common/components/modal/modal";
import LoadingIndicator from "../../../../common/components/LoadingIndicator/index";

//#endregion
var _reviewCommentsErrorMessage = <FormattedMessage {...messages.reviewCommentsErrorMessage} />;

//#region functional component
const FbcApprovalRequest = ({
    props,
    invoiceFromState,
    onSubmitForm,
    submitSuccess,
    loading,
    submitLoading,
    isApprovalReadonly,
    downloadLoading,
    closeDialog,
    onProceed,
    onRejectAction,
    action
}) => {
    useInjectReducer({ key: "fbcApprovalRequest", reducer });
    useInjectSaga({ key: "fbcApprovalRequest", saga });
    window.vlcredits.FeatureName = "FBC";
    const {
        requestHeaderProps,
        enrollmentDetailsProps,
        contactDetailsProps,
        lineItemDetailsProps,
        rebillPOProps,
        approvalListProps,
        additionalDetailsProps,
        approverDetailsProps,
    } = invoiceFromState ? sliceStateForComponents(invoiceFromState) : {};

    const readonly = invoiceFromState && invoiceFromState.isReadOnly;
    const isUserReadOnly = invoiceFromState && invoiceFromState.isUserReadOnly;
    isApprovalReadonly = readonly || isUserReadOnly ? true : false;
   
    return (
        <div>
            <form role="form" onSubmit={onSubmitForm}>
                <Modal
                    id="confirmationApprovalModal"
                    isOpen={submitSuccess && submitSuccess.success}
                    headerMessage={action === "1" ? messages.modalRejectMessgae : messages.modalApproveMessgae}
                    bodyMessage={messages.submit}
                    closeMessage={messages.modalClose}
                    close={() => {
                        window.location.href = "/fbc/search";
                    }}
                />

                <CreateRequest props={props} />

                <div className={!loading ? "hidden" : ""}>
                    <div className="col-md-8  remove-all-padding">
                        <LoadingIndicator />
                    </div>
                </div>
                <div className={!submitLoading ? "hidden" : ""}>
                    <div className="col-md-8  remove-all-padding">
                        <LoadingIndicator />
                    </div>
                </div>

                <div className={!downloadLoading ? "hidden" : ""}>
                    <div className="col-md-8  remove-all-padding">
                        <LoadingIndicator />
                    </div>
                </div>

                <RequestHeader {...requestHeaderProps} />

                <EnrollmentDetails {...enrollmentDetailsProps} isMultiInvoice={true} />
                <ContactDetails {...contactDetailsProps} />

                <AdditionalDetails
                    props={props}
                    approvalListProps={approvalListProps}
                    additionalDetailsProps={additionalDetailsProps}
                />

                <LineItemDetails otherDetails={requestHeaderProps}
                    props={props}
                    lineItemDetailsProps={lineItemDetailsProps}
                />

                <ApproverDetails
                    props={props}
                    approverDetailsProps={approverDetailsProps}
                    isApprovalReadonly={isApprovalReadonly}
                />

                <div className="col-sm-24 col-md-24 col-lg-24">
                    <div className="col-sm-24 col-md-24 col-lg-24">
                        <div className="row spacer-xs-bottom">
                            <div className={isApprovalReadonly === true ? "hidden" : ""}>
                                <div className="btn-group">
                                    <button
                                        type="button"
                                        className="btn btn-primary active"
                                        id="btnfbcRequestApproveSubmit"
                                        disabled={loading || submitLoading}
                                        onClick={onSubmitForm}
                                    >
                                        <FormattedMessage {...messages.btnApprove} />
                                    </button>
                                    <button
                                        type="button"
                                        id="rejectRequest"
                                        className="btn btn-secondary"
                                        onClick={onRejectAction}
                                        disabled={loading || submitLoading}
                                    >
                                        <FormattedMessage {...messages.btnReject} />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

//#endregion

function sliceStateForComponents(invoiceFromState) {
    const requestHeaderProps =
        invoiceFromState.PONumber && invoiceFromState.enrollmentNumber
            ? pick(invoiceFromState, [
                "requestId",
                "enrollmentNumber",
                "PONumber",
                "SystemPONumber",
                "cosmicCaseNumber",
                "isReadOnly",
                "userRole",
                "submitUserEmail",
            ])
            : {};

    const lineItemDetailsProps =
        invoiceFromState &&
            invoiceFromState.PONumber &&
            invoiceFromState.enrollmentNumber
            ? invoiceFromState.lineItemDetails
            : {};

    const rebillPOProps =
        invoiceFromState &&
            invoiceFromState.PONumber &&
            invoiceFromState.enrollmentNumber
            ? invoiceFromState.rebillInvoicePO
            : {};

    const approverDetailsProps =
        invoiceFromState &&
            invoiceFromState.PONumber &&
            invoiceFromState.enrollmentNumber
            ? pick(invoiceFromState, ["approverComment", "isReadOnly"])
            : {};

    const additionalDetailsProps =
        invoiceFromState &&
            invoiceFromState.PONumber &&
            invoiceFromState.enrollmentNumber
            ? pick(invoiceFromState, [
                "returnReason",
                "isFullCredit",
                "isBdApproved",
                "netAmount",
                "netCreditUSD",
                "handlingFeePercentage",
                "notes",
                "isReadOnly",
                "empowermentIdCSV",
                "invoiceAmountUSD",
                "invoiceAmount",
                "operationsCenterCode",
                "alternateROC",
                "programCode",
                "hasMultipleRequestCurrency",
                "hasMultipleRebillCurrency",
                "rebillInvoicePO",
            ])
            : {};

    const enrollmentDetailsProps =
        invoiceFromState.PONumber && invoiceFromState.enrollmentNumber
            ? pick(invoiceFromState, [
                "enrollmentNumber",
                "programCode",
                "masterAgmNumber",
                "billToCountryCode",
                "salesLocationCode",
            ])
            : {};

    const contactDetailsProps =
        invoiceFromState.PONumber && invoiceFromState.enrollmentNumber
            ? pick(invoiceFromState, [
                "customerPCN",
                "customerName",
                "customerEmail",
                "directPartnerPCN",
                "partnerName",
                "partnerEmail",
                "resellerPCN",
                "resellerName",
                "resellerEmail",
                "softwareAdvisorPCN",
                "softwareAdvisorName",
            ])
            : {};

    const approvalListProps =
        invoiceFromState.PONumber && invoiceFromState.enrollmentNumber
            ? invoiceFromState.approverList
            : {};

    return {
        requestHeaderProps,
        enrollmentDetailsProps,
        contactDetailsProps,
        lineItemDetailsProps,
        rebillPOProps,
        additionalDetailsProps,
        approverDetailsProps,
        approvalListProps
    };
}

function validateSubmitRequest(_action) {
    
    var txtApproverComments = document.getElementById("txtApproverComments");
    if (txtApproverComments) {
        txtApproverComments.setCustomValidity("");
        var reviewCommentsError = _reviewCommentsErrorMessage && _reviewCommentsErrorMessage.props && _reviewCommentsErrorMessage.props.defaultMessage;

        try {
            txtApproverComments.reportValidity();
        } catch (e) {
            //handles for IE browser
        }
        
        if (txtApproverComments && Helpers.isEmptyOrSpaces(txtApproverComments.value.trim())) {
            txtApproverComments.setCustomValidity(reviewCommentsError);
            try {
                txtApproverComments.reportValidity();
            } catch (e) {
                alert(reviewCommentsError)
            }
            return false;
        } else {
            txtApproverComments && txtApproverComments.setCustomValidity("");
            return true;
        }
    } else {
        return false;
    }


}
//#region  property type checks/validations

FbcApprovalRequest.propTypes = {
    action: PropTypes.string,
    closeDialog: PropTypes.func,
    downloadLoading: PropTypes.bool,
    invoiceFromState: PropTypes.object,
    isApprovalReadonly: PropTypes.bool,
    loading: PropTypes.bool,
    onProceed: PropTypes.func,
    onRejectAction: PropTypes.func,
    onSubmitForm: PropTypes.func,
    submitLoading: PropTypes.bool,
    submitSuccess: PropTypes.bool,
};

//#endregion

//#region mapState, mapDispatch


const mapStateToProps = createStructuredSelector({
    invoiceFromState: makeSelectInvoice(),
    action: makeSelectFbcAction(),
    submitSuccess: makeSelectSelectFbcApprovalSuccess(),
    loading: makeSelectLoading(),
    submitLoading: makeSelectSelectFbcApprovalLoading(),
});

function mapDispatchToProps(dispatch) {
    return {
        onRejectAction: evt => {
            if(window && window.vlcredits.envData.Services.isRejectionReason == "true"){
            var _isValidRejection = false;
            var _dvErrorMessage = document.getElementById("dvRejectionReasonsErrorMessage");
            var _dvOtherErrorMessage = document.getElementById("dvOtherRejectionReasonsErrorMessage");
            var _txtOtherRejectionReason = document.getElementById("txtOtherRejectionReason");
            var _ddlReasons = document.getElementById("ddlRejectionReasons");
            var _dvSubmitErrorMessage = document.getElementById("dvSubmitErrorMessage");
            if (_ddlReasons) {
                var _selectedReason = _ddlReasons.value;
                if (_selectedReason && _selectedReason === "-1") {
                    _isValidRejection = false;

                    if (_dvErrorMessage)
                        _dvErrorMessage.style.display = "block";

                    if (_dvSubmitErrorMessage)
                        _dvSubmitErrorMessage.style.display = "none";

                } else if (_selectedReason && _selectedReason === "Others") {
                    if (_txtOtherRejectionReason && _txtOtherRejectionReason.value.trim() === "") {
                        _isValidRejection = false;
                        if (_dvOtherErrorMessage)
                            _dvOtherErrorMessage.style.display = "block";

                    } else {
                        if (_txtOtherRejectionReason.value.trim().length < 50) {
                           _txtOtherRejectionReason.style.backgroundColor = "red";
                            _txtOtherRejectionReason.focus();   
                            _isValidRejection = false;
                            if (_dvOtherErrorMessage)
                                _dvOtherErrorMessage.style.display = "none";
                        } else {
                            _txtOtherRejectionReason.style.backgroundColor = "";
                            _isValidRejection = true;
                            if (_dvOtherErrorMessage)
                                _dvOtherErrorMessage.style.display = "none";
                        }
                    }
                } else {
                    _isValidRejection = true;
                    if (_dvErrorMessage)
                        _dvErrorMessage.style.display = "none";
                }
            } else {
                _isValidRejection = false;
            }
            var _txtApproverComments = document.getElementById("txtApproverComments");
            if (_isValidRejection && _txtApproverComments && _txtApproverComments.value.trim() === "") {
                _isValidRejection.style.className = "alert alert-error";
                _isValidRejection = false;
            }
            if (_isValidRejection) {
                evt.target.disabled = true;
                window.scrollTo(0, 0);
                dispatch(submitFbcApprovalRequest("1"));
            }
        } else {
            if (evt !== undefined && evt.preventDefault) evt.preventDefault();
            {
                var isValid = validateSubmitRequest(1);
                if (isValid) {
                    evt.target.disabled = true;
                    dispatch(submitFbcApprovalRequest("1"));
                    window.scrollTo(0, 0);
                }

            }

        }
        },

        onSubmitForm: evt => {
            if (evt !== undefined && evt.preventDefault) evt.preventDefault();
            {
                if(window && window.vlcredits.envData.Services.isRejectionReason == "true"){
                var _ddlReasons = document.getElementById("ddlRejectionReasons");
                var _dvSubmitErrorMessage = document.getElementById("dvSubmitErrorMessage");
                var _txtApproverComments = document.getElementById("txtApproverComments");
                var _dvApproverErrorMessage = document.getElementById("dvtxtApproverCommentsErrorMessage");
                _dvApproverErrorMessage.style.display = "none";
                _dvSubmitErrorMessage.style.display = "none";
                if (_ddlReasons) {
                    var _selectedReason = _ddlReasons.value;
                    if (_selectedReason && _selectedReason === "-1" && _txtApproverComments && _txtApproverComments.value.trim().length > 0) {
                        dispatch(submitFbcApprovalRequest("0"));
                        window.scrollTo(0, 0);
                    } else if (_selectedReason && _selectedReason === "-1" && _txtApproverComments && _txtApproverComments.value.trim() === "") {
                        _dvApproverErrorMessage.style.display = "block";
                    } else {
                        _dvSubmitErrorMessage.style.display = "block";
                            _ddlReasons.focus();
                    }
                }
            } else {
                var isValid = validateSubmitRequest(0);
                if (isValid) {
                    evt.target.disabled = true;
                    dispatch(submitFbcApprovalRequest("0"));
                    window.scrollTo(0, 0);
                }
            }
            }            
        },

        onProceed: (evt) => {
            if (evt !== undefined && evt.preventDefault) evt.preventDefault();
            {
                var isValid = validateSubmitRequest(0);
                if (isValid) {
                    evt.target.disabled = true;
                    dispatch(submitFbcApprovalRequest("0"));
                    window.scrollTo(0, 0);
                }
            }

        },
        closeDialog: (evt) => {
            if (evt !== undefined && evt.preventDefault) evt.preventDefault();
            {
                dispatch(closeDialog(false));
            }
            window.scrollTo(0, 0);
        },
    };
}

//#endregion

//#region connect to store

const withConnect = connect(mapStateToProps, mapDispatchToProps);

//#endregion

export default compose(withConnect)(
    injectIntl(FbcApprovalRequest)
);
