import React, { memo } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Redirect } from 'react-router'
import { createStructuredSelector } from 'reselect';
import { Switch, Route } from 'react-router-dom';
import SubmitHome from '../../../submit/operations/containers/submitHome';
import CancelFutureBilligOpsHome from '../../../submit/operations/containers/cancelFutureBillingHome';
import  SearchCreditsOps  from '../../../search/operations/components/index';
import  ApprovalSearch  from '../../../approvalSearch/components/index';
import Approve from '../../../approval/approver/creditApprover/containers/home';
import PrivacyStatement from '../privacy/index';
import CancelFutureBillingMenu from './CancelFutureBillingMenu';
import CancelFutureBillingSearch from '../../../search/cancelFutureBilling/components/index';
import CancelFutureBillingSearchHome from "../../../approval/approver/fbcApprover/home";
import MultiTenantHome from '../../../submit/operations/containers/multiTenantHome';
import MultiTenantSearchHome from '../../../submit/operations/containers/multiTenantSearchHome';
import MultiTenantMenu, { isMtEnabledForUser } from './MultiTenantMenu';
import { isFbcEnabled } from '../../internals/utils/featureFlag';
import { isEPTEnabled } from '../../internals/utils/featureFlag' ;
import multiTenant from '../../../search/multiTenant/components/index';
import { makeSelectUserInfo } from '../../app/selectors';
import UnAuthorised from './../unauthroised';
import '../../app/accessibility-style.css';

export function CreditSubmitterMenu(props) {

    const { formatMessage } = props.intl;
    const isFbcEnabledFlag = isFbcEnabled();

    var lblCreateRequestText = formatMessage({ id: "lblCreateRequestText" })
    var lblCreateText = formatMessage({ id: "lblCreateText" })
    var lblSearchText = formatMessage({ id: "lblSearchText" })
    var lblMultiCreateText = formatMessage({ id: "lblMultiCreateText" })
    var lblApprovalText = formatMessage({ id: "lblApprovalText" })

    var isMTEnabled = isMtEnabledForUser(props.userInfo);

    var isEPTEnabledFlag = isEPTEnabled()
    
    return (

        <div className="row remove-all-margin">
            <div className="col-md-5 col-lg-3 side-nav-wrap">
                <div className="onedash-container" data-bi-area="OneDashboardSide" role="navigation">
                    <nav id="onedash-side-navigation" className="onedash-side-navigation mobileHide is-open" aria-label="menubar">

                        <div id="onedash-navigation-list" className="onedash-navigation-list">
                            <div id="onedash-top-menus">
                                <input type="hidden" id="applicationMenuDropDownCommon" data-accountid="0" name="common-accountid" />
                                <div id="partner-company-name1"
                                    className="onedash-accountpicker-account is-visible">
                                    <span className="activeAccount activeAccountTitle" aria-label={lblCreateRequestText}>{lblCreateRequestText}</span>
                                </div>

                                <div id="onedash-internaltools-menu-title-1" className="onedash-navigation-menu-title is-active">
                                    <ul role="menu">
                                        <li className="onedash-navigation-menu-item" role="none">
                                            <a href="/submitter/create" id="lnkCreateRequest" role="menuitem" className="focusdiv">
                                                <div className="onedash-navigation-category" aria-label={lblCreateRequestText + lblCreateText}>{lblCreateText}</div>
                                            </a>
                                        </li>
                                        {isEPTEnabledFlag === "True" ?
                                            <li className="onedash-navigation-menu-item" role="none">
                                                <a
                                                    href="/submitter/multiCreate"
                                                    id="lnkMultiCreateRequest"
                                                    role="menuitem"
                                                    className="focusdiv"
                                                >
                                                    <div
                                                        className="onedash-navigation-category"
                                                        aria-label={lblCreateRequestText + lblMultiCreateText}
                                                    >
                                                        {lblMultiCreateText}
                                                    </div>
                                                </a>
                                            </li>
                                            : ""}

                                        <li className="onedash-navigation-menu-item" role="none">
                                            <a href="/submitter/search" id="linkListSubmitterSearch" role="menuitem">
                                                <div className="onedash-navigation-category" aria-label={lblCreateRequestText + lblSearchText}>{lblSearchText}</div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>

                                <div id="partner-company-name2"
                                    className="onedash-accountpicker-account is-visible">
                                    <span className="activeAccount activeAccountTitle" aria-label={lblApprovalText} >{lblApprovalText}</span>
                                </div>

                                <div id="onedash-internaltools-menu-title-2" className="onedash-navigation-menu-title is-active">
                                    <ul role="menu">
                                        <li className="onedash-navigation-menu-item" role="none">
                                            <a href="/approver/approvalSearch" id="linkListApproverSearch" role="menuitem">
                                                <div className="onedash-navigation-category" aria-label={lblApprovalText + lblSearchText}>{lblSearchText}</div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    {isFbcEnabledFlag === "True" ? <CancelFutureBillingMenu />:null} 
                                </div>
                                <div>
                                    {isMTEnabled && <MultiTenantMenu />}
                                </div>
                            </div>
                        </div>
                        <div><PrivacyStatement /></div>
                    </nav>
                </div>
            </div>

            <div className="col-sm-24 col-md-19 col-lg-21 rightview">
                <Switch>
                    <Route path="/submitter/create/:invoice?/:requestId?/:requestStatus?" component={SubmitHome} />
                    <Route path="/submitter/search" component={SearchCreditsOps} />
                    <Route path="/approver/approve/:invoice?/:requestId?/:requestStatus?" component={Approve} />
                    <Route path="/approver/approvalSearch" component={ApprovalSearch} />
                    <Route path="/fbc/request:docId?/:requestId?/:requestStatus?" component={CancelFutureBillingSearchHome} />
                    <Route path="/fbc/search" component={CancelFutureBillingSearch} />
                    <Route path="/submitter/multiCreate/:enrollmentNumber?/:systemPONumber?/:requestId?/:requestStatus?" render={() => <SubmitHome isMultiInvoice={true} />} />
                    <Route path="/fbc/create/:enrollmentNumber?/:systemPONumber?/:requestId?/:requestStatus?" render={() => <CancelFutureBilligOpsHome />} />
                    <Route path="/" exact>
                        <Redirect to="/submitter/create" />
                    </Route>
                    {isMTEnabled ? 
                        <>
                            <Route path="/multitenant/create/:leadEnrollmentNumber?" render={() => <MultiTenantHome />} />
                            <Route path="/multitenant/search" component={multiTenant} />
                            <Route path="/multitenant/request/:enrollmentNumber?/:requestId?/:requestStatus?" component={MultiTenantSearchHome}/>
                        </> :
                        <>
                            <Route path="/multitenant/create/:leadEnrollmentNumber?" render={() => <UnAuthorised />} />
                            <Route path="/multitenant/search" component={() => <UnAuthorised/>} />
                            <Route path="/multitenant/request/:enrollmentNumber?/:requestId?/:requestStatus?" component={() => <UnAuthorised />}/>
                        </>

                    }
                    
                    
                </Switch>
            </div>

        </div>

    );
}

const mapStateToProps = createStructuredSelector(
    {userInfo: makeSelectUserInfo()}
);

const withConnect = connect(mapStateToProps, null);
export default compose(withConnect, memo)(injectIntl(CreditSubmitterMenu));
