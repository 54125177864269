import * as DomainData from './requestStatusRoleBased';
import { isCancelledEnabled, isROCOverridenEnabled, allowedProgramCode, BlockedCPSCountryCode, FBCCPSROCReturnReasons } from './featureFlag';
import moment from 'moment';
var CUSTOMDATE = "Custom";
var dateFormatForServer = "YYYY-MM-DD";
var dateFormatForDisplay = window.dateFormat ? window.dateFormat.toUpperCase() : dateFormatForServer;

const FilterButtonDaysList = [{ key: 1, value: "1D" }, { key: 7, value: "1W" }, { key: 30, value: "1M" }, { key: CUSTOMDATE, value: CUSTOMDATE }];
var PartnerReturnReasons = ["Y17", "Y18", "Y22", "Y23", "Y24", "Y25", "Y26", "Y27", "Y88", "Y90","X34"];

var FieldReturnReasons = ["Y17", "Y18", "Y22", "Y23", "Y24", "Y25", "Y26", "Y27", "Y35", "Y85", "Y86", "Y88", "Y90", "Y93", "Y94", "Y95", "Y96", "Y97", "X31", "X32", "X33", "X34"];
var OpsReturnReasons = ["Y24", "Y27", "Y90", "Y35", "Y01", "Y86", "Y91", "Y92", "Y87", "Y18", "Y25", "Y26", "Y22", "Y17", "Y88", "Y23", "Y61", "Y93", "Y94", "Y85", "Y57", "Y89", "Y95", "Y96", "Y97", "X31", "X32", "X33", "X34"];

var AvailablePrograms = ["CC", "E6", "EU", "HV", "I3", "OVS", "OVS", "SC", "S6", "SLP", "SP", "A2", "USG", "VS", "GGA", "C4", "VB"];

var OpsCPSReturnReason = ["Y24", "Y27", "Y90", "Y35", "Y01", "Y86", "Y91", "Y92", "Y87", "Y18", "Y25", "Y26", "Y22", "Y17", "Y88", "Y23", "Y61", "Y93", "Y94", "Y85", "Y57", "Y89", "Y95", "Y96", "Y97", "YAB", "X31", "X32", "X33", "X34"];
//var FieldReturnReasons = ["Y17", "Y18", "Y19", "Y22", "Y23", "Y24", "Y25", "Y26", "Y27", "Y35", "Y85", "Y86", "Y88", "Y90", "Y93", "Y94", "Y95", "Y96", "Y97"];
//var OpsReturnReasons = ["Y24", "Y27", "Y90", "Y35", "Y01", "Y86", "Y91", "Y92", "Y87", "Y18", "Y25", "Y26", "Y22", "Y17", "Y88", "Y23", "Y61", "Y93", "Y94", "Y19", "Y85", "Y57", "Y89", "Y95", "Y96", "Y97"];


var RejectionReasons = [
    { code: "R01", value: "Missing required supporting documentation", emailContent: "This credit request is rejected as it is missing the required supporting documentation (such as customer evidence, field approval, amendment, etc). " },
    { code: "R02", value: "Invalid Attachment", emailContent: "This credit request is rejected as the attachment is invalid, is missing information or has errors." },
    { code: "R03", value: "Invalid Customer acknowledgement form", emailContent: "This credit request is rejected as the customer acknowledgment form is invalid, is missing information or has errors." },
    { code: "R04", value: "Out of policy-over 60 days", emailContent: "This credit request is rejected as it is out of policy due to invoice being over 60 days from invoice date." },
    { code: "R05", value: "Out of Policy-over 180 days", emailContent: "This credit request is rejected as it is out of policy due to invoice being over 180 days from invoice date. " },
    { code: "R06", value: "Incorrect Credit reason selected", emailContent: "This credit request is rejected as the credit reason selected is incorrect. Please resubmit with the correct reason. " },
    { code: "R07", value: "Incorrect Rebill Invoice/ PO", emailContent: "This credit request is rejected as the rebill is incorrect. Please resubmit with a valid rebill or as a full credit with no rebill." },
    { code: "R08", value: "Rebill/ Replacement order is missing", emailContent: "This credit request cannot be processed as it it missing the rebill/replacement order. Please resubmit with a valid rebill order. " },
    { code: "R09", value: "Insufficient or missing business Justification", emailContent: "This credit request is rejected as the business justification is insufficient or missing. " },
    { code: "R10", value: "Not programmatic-Order cannot be cancelled due to program rules", emailContent: "This credit request cannot be processed as it is not programatic. Order cannot be cancelled due to program rules." },
    { code: "R11", value: "If you are choosing System error, proof of system error is required.", emailContent: "This credit request is rejected because if you are choosing System error, proof of system error /bug number is required.  " },
    { code: "R12", value: "If you are choosing the customer as the source of the error, you must attach a letter of confirmation from the customer.", emailContent: "If you are selecting Customer as the source of the error, you must attach a letter of confirmation from the customer. Please resubmit with customer confirmation." },
    { code: "R13", value: "For Ops Submissions - Non Ops approved should not be checked", emailContent: "Non Ops approved should not be checked." },
    { code: "R14", value: "For Ops Submissions - Non Ops approved should be checked", emailContent: "Non Ops approved should be checked." },
    { code: "R15", value: "For Ops Submissions - Incorrect or missing Empowerment ID entered", emailContent: "Incorrect or missing Empowerment ID entered" },
    { code: "R16", value: "Incorrect restock fee applied", emailContent: "Incorrect restock fee applied. Please resubmit." },
    { code: "R17", value: "Credit request submitted in error", emailContent: "This credit is rejected as it was submitted in error." },
    { code: "R18", value: "For Ops Submissions - Missing or Incorrect Case Number indicated", emailContent: "This credit request is rejected due to missing or Incorrect Case Number indicated. Please resubmit with valid case number." },
    { code: "R19", value: "For Ops Submissions - Incorrect revenue amount", emailContent: "This credit request is rejected because the net credit revenue amount is incorrect. Please resubmit with correct applicable rebill system PO amount." },
    { code: "R20", value: "Ops error in credit request", emailContent: "This credit request is rejected because there is an operations error in the request. Microsoft Operations will resubmit the credit correctly." },
    { code: "R21", value: "System error in credit request", emailContent: "This credit is rejected as there is a System error in the request." },
    { code: "R22", value: "Incorrect ROC ", emailContent: "This credit request is rejected because the ROC selected is incorrect. Please resubmit with the correct ROC. (ROC should be the Regional Operations Center where the order was placed)." },
    { code: "Others", value: "Other" }];

RejectionReasons = sortByKeyAsc(RejectionReasons, "value");

var FbcRejectionReasons = [
    { code: "FR1", value: "Invalid agreement type for reduction", emailContent: "This request has been rejected, as the agreement type selected for the reduction request is invalid" },
    { code: "FR2", value: "Programmatic - SKU is non reduction eligible", emailContent: "This request has been rejected, as the products listed for reduction are not eligible based on the product terms list" },
    { code: "FR3", value: "Reduction Request Invalid – Enrollment Credit Hold Encountered", emailContent: "This request has been rejected by Credit & Collections due to an existing Credit Hold. At this time, we ask that you reach out to Credit Collections to clarify the issue" },
    { code: "FR4", value: "Reduction Request Invalid – Minimum Commitment Levels Affected", emailContent: "This request has been rejected, as the resulting product quantities would be below the minimum commitment levels as set established on this enrolment’s product selection form." },
    { code: "FR5", value: "Request completed with a Credit and Rebill", emailContent: "This request has been processed via Credit and Rebill, due to a late submission." },
    { code: "Others", value: "Other" }];

FbcRejectionReasons = sortByKeyAsc(FbcRejectionReasons, "code");


var MTRejectionReasons = [
    { code: "R01", value: "Missing required supporting documentation", emailContent: "This allocation request is rejected as it is missing the required supporting documentation (ex: Ship To detail, SEZ detail etc)​" },
    { code: "R02", value: "Invalid Attachment", emailContent: "This allocation request is rejected as the attachment is invalid, is missing information or has errors." },
    { code: "R03", value: "Invalid Customer acknowledgement form​", emailContent: "This allocation request is rejected as the customer acknowledgment form is invalid, is missing information or has errors." },
    { code: "MR1", value: "Invalid Tax Exemption form​", emailContent: "This allocation request is rejected as the Tax Exemption form is invalid, is missing information or has errors." },
    { code: "Others", value: "Other" }];

MTRejectionReasons = sortByKeyAsc(MTRejectionReasons, "value");



var CancellationReasons = [
    { code: "CAN07", value: "Approval issues" },
    { code: "CAN06", value: "Credit was submitted in error" },
    { code: "CAN05", value: "Error in credit request" },
    { code: "CAN04", value: "Incorrect net amount" },
    { code: "CAN03", value: "Incorrect rebill PO" },
    { code: "CAN01", value: "Restock fee issues" },
    { code: "CAN02", value: "Wrong return reason selection" },
    { code: "Others", value: "Other" }
];


var CurencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
});

const OPS_CENTER = [
    { value: 'USO', label: 'AOC - American Ops Center' },
    { value: 'EOC', label: 'EOC - European Ops Center' },
    { value: 'APO', label: 'APOC - Asia Pacific Ops Center' }

];

const GroupBy = (keys, array) =>
    array.length > 0 && array.reduce((objectsByKeyValue, obj) => {
        const value = keys.map(key => obj[key]).join('_');
        objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
        return objectsByKeyValue;
    }, {});

function getFilteredColumns() {
    const columns = [
        {
            dataField: 'partNumber',
            text: 'Part Number',
            title: true,
            headerTitle: true
        },
        {
            dataField: 'salesOrderLineItemId',
            text: "SOLI Number",
            title: (cell, row, rowIndex, colIndex) => `${cell}`,
            headerTitle: true
        }, {
            dataField: 'productDescription',
            text: 'Product Descripton',
            title: (cell, row, rowIndex, colIndex) => `${cell}`,
            headerTitle: true
        }, {
            dataField: 'coverage_period',
            text: 'Coverage Period',
            title: (cell, row, rowIndex, colIndex) => `${cell}`,
            headerTitle: true
        }, {
            dataField: 'quantity',
            text: 'Invoiced Quantity',
            title: (cell, row, rowIndex, colIndex) => `${cell}`,
            headerTitle: false

        }, {
            dataField: 'adjustedQuantity',
            text: 'Remaining Quantity',
            headerTitle: true,
            editable: true
        }, {
            dataField: 'usageCountryCode',
            text: 'Usage Country',
            title: (cell, row, rowIndex, colIndex) => `${cell}`,
            headerTitle: true
        }, {
            dataField: 'billingOptionCode',
            text: 'Billing Option',
            title: (cell, row, rowIndex, colIndex) => `${cell}`,
            headerTitle: true
        }, {
            dataField: 'usedPrice',
            text: 'Unit Price',
            title: (cell, row, rowIndex, colIndex) => `${cell}`,
            headerTitle: true
        }, {
            dataField: 'adjustedPrice',
            text: 'Adjusted Price',
            title: (cell, row, rowIndex, colIndex) => `${cell}`,
            headerTitle: true
        }, {
            dataField: 'extendedAmount',
            text: 'Extended Amount',
            title: (cell, row, rowIndex, colIndex) => `${cell}`,
            headerTitle: true
        },
        {
            dataField: 'programOfferingCode',
            text: 'Program Offering',
            title: (cell, row, rowIndex, colIndex) => `${cell}`,
            headerTitle: true
        }, {
            dataField: 'endCustomrPurchaseOrderNumber',
            text: "Customer PO",
            title: (cell, row, rowIndex, colIndex) => `${cell}`,
            headerTitle: true
        }];

    return columns;
}
function DateFormat(mydate) {
    var date = new Date(mydate);
    return moment(date).format(dateFormatForServer);
}

function DateFormatSearch(mydate) {
    if (mydate) {
        var formattedDate = moment.utc(mydate).format(dateFormatForServer);
        if (formattedDate.toUpperCase() === "INVALID DATE") {
            formattedDate = moment.utc(mydate, dateFormatForDisplay).format(dateFormatForServer);
        }
        return formattedDate;
    } else {
        console.log("DateFormatSearch: mydate is null");
    }
}

function DateFormatForDisplay(mydate) {
    dateFormatForDisplay = window.dateFormat ? window.dateFormat.toUpperCase() : dateFormatForServer;
    var date = new Date(mydate);
    return moment(date).format(dateFormatForDisplay);
}

function FormatInputDateFromDateRangeAction(mydate) {
    try {
        dateFormatForDisplay = window.dateFormat ? window.dateFormat.toUpperCase() : dateFormatForServer;
        return moment(mydate).format(dateFormatForDisplay);
    }
    catch (err) {
        console.log("Exception " + err);
    }

}

function isINT(n) {
    return Number(n) === n && n % 1 === 0;
}
function isFLOAT(n) {
    return Number(n) === n && n % 1 !== 0;
}

const isEmpty = function (input) {
    if (typeof input === 'array') {
        return input.length === 0;
    }

    return !input || Object.keys(input).length === 0;
}

function ConvertToDecial(number, decimalPlaces) {
    if (number) {
        if (decimalPlaces) {
            return ToFixedTruncate(number * 1, decimalPlaces * 1);
        } else {
            return ToFixedTruncate(number * 1);
        }
    } else {
        return isNaN(number) ? 0 : number;
    }
}

function ToFixedTruncate(x, n) {
    if (x === 0) {
        return x;
    }
    if (x) {
        const v = (typeof x === 'string' ? x : x.toString()).split('.');
        if (n && n <= 0) return v[0];
        let f = v[1] || '';
        if (f.length > n) return `${v[0]}.${f.substr(0, n)}`;
        while (f.length < n) f += '0';
        return `${v[0]}.${f}`;
    } else {
        console.log("x=> is null" + x);
    }
}

function isEmptyOrSpaces(str) {
    return str === null || str === undefined || str.match(/^ *$/) !== null;
}

function sortInvoiceList(invoiceList) {
    return invoiceList.sort(custom_invoice_sort);
}

function custom_invoice_sort(a, b) {
    return new Date(a.invoiceDate).getTime() - new Date(b.invoiceDate).getTime();
}

function custom_date_sort(a, b) {
    return new Date(a.coverageStartDate).getTime() - new Date(b.coverageStartDate).getTime();
}

function CalculateCoveragePeriod(lineItems) {

    var finalLineItems = [];
    for (var key in lineItems) {
        if (lineItems.hasOwnProperty(key)) {

            var _arrCP = [];
            var _len = lineItems[key] && lineItems[key].length;
            if (_len <= 0) _len = 1;
            lineItems[key].sort(custom_date_sort);

            for (var m = 0; m < lineItems[key].length; m++) {
                var data = lineItems[key] && lineItems[key][m];
                if (data && data.coverageStartDate && data.coverageEndDate) {
                    var sDate = DateFormat(new Date(data.coverageStartDate));
                    var eDate = DateFormat(new Date(data.coverageEndDate));
                    if (window.vlcredits.FeatureName === "FBC") {
                        _arrCP.push({
                            "coverageStartDate": sDate, "coverageEndDate": eDate, "coveragePeriod": sDate + ' To ' + eDate, "salesOrderLineItemId": data.salesOrderLineItemId, "billingMultipler": data.billingMultipler, "multiplier": _len, "billOnDate": data.billOnDate
                        });
                    } else {
                        _arrCP.push({
                            "coverageStartDate": sDate, "coverageEndDate": eDate, "coveragePeriod": sDate + ' To ' + eDate, "salesOrderLineItemId": data.salesOrderLineItemId, "billingMultipler": data.billingMultipler, "multiplier": _len
                        });
                    }
                }
                _len--;
            }

        }
        lineItems[key][0].coverage_period = _arrCP;
        finalLineItems.push(lineItems[key][0]);
    }
    return finalLineItems;
}

function GetLineItemBySID(soliID, lineItems) {
    var lineItem;
    for (var i = 0; i < lineItems.length; i++) {
        var _c = lineItems[i];
        if (parseInt(soliID) === parseInt(_c.salesOrderLineItemId)) {
            lineItem = _c;
        }
    }
    return lineItem;
}



function ResetForm(formID) {
    if (document.getElementById(formID))
        document.getElementById(formID).reset();
}
function getNextDays(day) {
    var today = new Date();
    today.setDate(today.getDate() + day);
    console.log("Date : " + today);
    console.log("UTC Date : " + today.toUTCString());
    return DateFormatSearch(today.toUTCString());
}

function GetPartnerStatusByCode(Code) {
    var result = DomainData.PartnerRequestStatus.filter(function (c) {
        return c.key === Code;
    });
    if (result.length) {
        return result[0].value;
    } else {
        return Code;
    }
}
function GetStatusByCode(Code) {
    var result = DomainData.requestStatus_Other.filter(function (c) {
        return c.key === Code;
    });
    if (result.length) {
        return result[0].value;
    } else {
        return Code;
    }
}

function GetFbcOpsStatusByCode(Code) {
    var result = DomainData.OpsRequestStatus_FBC.filter(function (c) {
        return c.key === Code;
    });
    if (result.length) {
        return result[0].value;
    } else {
        return Code;
    }
}

function GetFbcPartnerStatusByCode(Code) {
    var result = DomainData.PartnerRequestStatus_FBC.filter(function (c) {
        return c.key === Code;
    });
    if (result.length) {
        return result[0].value;
    } else {
        return Code;
    }
}


function GetMTPartnerRequestStatusByCode(Code) {
    var result = DomainData.MT_PartnerRequestStatus.filter(function (c) {
        return c.key === Code;
    });
    if (result.length) {
        return result[0].value;
    } else {
        return Code;
    }
}

function GetMTOpsRequestStatusByCode(Code) {
    // Solution for BUG# 41351892 Mapping to MT_OpsRequestStatusV2 instead of MT_OpsRequestStatus.
    var result = DomainData.MT_OpsRequestStatusV2.filter(function (c) {
        return c.key === Code;
    });
    if (result.length) {
        return result[0].value;
    } else {
        return Code;
    }
}


function GetCountriesFromDomain(DomainData) {
    if (!isEmpty(DomainData)) {
        if (DomainData.find(x => x.key === 'VLCountry')) {
            return DomainData.find(x => x.key === 'VLCountry').value;
        }
    }
}
function GetProgramsFromDomain(DomainData) {
    if (!isEmpty(DomainData)) {
        if (DomainData.find(x => x.key === 'VLProgram')) {
            return DomainData.find(x => x.key === 'VLProgram').value;
        }
    }
}
function GetBillingOptionsFromDomain(DomainData) {
    if (!isEmpty(DomainData)) {
        if (DomainData.find(x => x.key === 'VLBillingOption')) {
            return DomainData.find(x => x.key === 'VLBillingOption').value;
        }
    }
}
function GetCurrencyListFromDomain(DomainData) {
    if (!isEmpty(DomainData)) {
        if (DomainData.find(x => x.key === 'VLCurrency')) {
            return DomainData.find(x => x.key === 'VLCurrency').value;
        }
    }
}
function GetPurchaseOrderNameFromDomain(DomainData) {
    if (!isEmpty(DomainData)) {
        if (DomainData.find(x => x.key === "VLPurchaseOrderType")) {
            return DomainData.find(x => x.key === 'VLPurchaseOrderType').value;
        }
    }
}
function GetCountryListForDropDown(serviceDomainData) {

    if (window.countries) {
        return window.countries;
    }

    var _countries = [], _countriesList = [];

    if (!isEmpty(serviceDomainData)) {
        _countriesList = GetCountriesFromDomain(serviceDomainData);
    } else {
        _countriesList = DomainData.CountryList;
    }

    _countriesList && !isEmpty(_countriesList) && _countriesList.forEach((c) => {
        return _countries.push({ 'key': c.CountryCode, 'value': c.CountryName })
    });

    _countries = sortByKeyAsc(_countries, "value");
    window.countries = _countries;

    return _countries;
}


function GetProgramCodesForDropDown(serviceDomainData) {

    if (window.programs) {
        return window.programs;
    }

    var _programs = [], _programList = [];

    if (!isEmpty(serviceDomainData)) {
        _programList = GetProgramsFromDomain(serviceDomainData);
    }
    else {
        _programList = DomainData.ProgramList;
    }


    _programs = _programList && _programList.length > 0 && _programList.filter(function (n) {
        return AvailablePrograms.indexOf(n.ProgramCode.trim()) !== -1;
    });

    var _programsForDropDown = [];
    _programs && !isEmpty(_programs) && _programs.forEach((c) => {
        return _programsForDropDown.push({ 'key': c.ProgramCode, 'value': c.ProgramName })
    });

    _programsForDropDown = sortByKeyAsc(_programsForDropDown, "value");
    window.programs = _programsForDropDown;
    return _programsForDropDown;
}


function GetReturnReasonsFromDomain(DomainData) {
    if (!isEmpty(DomainData)) {
        if (DomainData.find(x => x.key === "VLAdjustmentReason")) {
            return DomainData.find(x => x.key === 'VLAdjustmentReason').value;
        }
    }
}
function GetCountryByCode(Code, domainData) {
    var _countriesList = GetCountriesFromDomain(domainData);
    var result = _countriesList && !isEmpty(_countriesList) && _countriesList.filter(function (c) {
        return c.CountryCode === Code;
    });
    if (result && result.length) {
        return result[0].CountryName;
    }
}
function GetBillingOptionCode(Code, domainData) {
    var _billingOptions = GetBillingOptionsFromDomain(domainData);
    var result = _billingOptions && !isEmpty(_billingOptions) && _billingOptions.filter(function (c) {
        return c.BillingOptionCode === Code;
    });
    if (result && result.length) {
        return result[0].BillingOptionName;
    } else {
        return Code;
    }
}
function GetPurchaseOrderNameByCode(Code, domainData) {
    var _purchaseOrderTypes = GetPurchaseOrderNameFromDomain(domainData);
    var result = _purchaseOrderTypes && !isEmpty(_purchaseOrderTypes) && _purchaseOrderTypes.filter(function (c) {
        return c.PurchaseOrderTypeCode === Code;
    });
    if (result && result.length) {
        return result[0].PurchaseOrderTypeName;
    } else {
        return Code;
    }
}
function GetProgramNameByCode(Code, domainData) {
    var _programsList = GetProgramsFromDomain(domainData);
    var result = _programsList && !isEmpty(_programsList) && _programsList.filter(function (c) {
        return c.ProgramCode === Code;
    });
    if (result && result.length) {
        return result[0].ProgramName;
    } else {
        return Code;
    }
}
function GetCurrencyNameByCode(Code, domainData) {
    var result = DomainData.Currency.filter(function (c) {
        return c.CurrencyCode === Code;
    });
    if (result && result.length) {
        return result[0].CurrencyName;
    } else {
        return Code;
    }
}
function GetReturnReasonsByCode(Code, domainData) {
    //var _returnReasons = GetReturnReasonsFromDomain(domainData);  
    var _returnReasons = DomainData.TotalReturnReasons;
    var result = _returnReasons && !isEmpty(_returnReasons) && _returnReasons.filter(function (c) {
        return c.AdjustmentReasonCode === Code;
    });
    if (result && result.length) {
        return result[0].AdjustmentReasonName;
    } else {
        return Code;
    }
}

function GetReturnReasonsByCodeNew(Code, domainData) {
    var _returnReasons = DomainData.TotalReturnReasons;
    var result = _returnReasons && !isEmpty(_returnReasons) && _returnReasons.filter(function (c) {
        return c.AdjustmentReasonCode === Code;
    });
    if (result && result.length) {
        return result[0].AdjustmentReasonName;
    } else {
        return Code;
    }
}

function GetSalesLocationByCode(Code, domainData) {
    var _salesLocations = DomainData.SalesLocationMasterDataStatic;
    var result = _salesLocations && !isEmpty(_salesLocations) && _salesLocations.filter(function (c) {
        return c.SalesLocationCode === Code;
    });
    if (result && result.length) {
        return result[0].SalesLocationName;
    } else {
        return Code;
    }
}

function GetAdjustedLineItems(lineItems) {
    var adjustedLineItems = [];
    lineItems.forEach(function (item) {
        if (item.isAdjusted) {
            adjustedLineItems.push(item);
        }
    });
    return adjustedLineItems;
}

function GetCosmicCaseNumber(manualCosmicCaseNumber) {

    if (manualCosmicCaseNumber !== undefined && manualCosmicCaseNumber !== "") {
        var tikcetList = []
        var strings = manualCosmicCaseNumber.split(",");
        for (var i = 0; i < strings.length; i++)
            tikcetList.push({ ticketId: strings[i], type: "manual" })

        return tikcetList;
    } else return undefined;


}

function GetReturnReasonsForDropDown(serviceDomainData, role) {
    var _returnReasons = [], _returnReasonsList = [];

    _returnReasonsList = DomainData.TotalReturnReasons;
    _returnReasonsList = sortByKeyAsc(_returnReasonsList, "AdjustmentReasonName");
    var RoleBasedReturnReasons = OpsReturnReasons;
    switch (role) {
        case "VSC":
        case "VPU":
            RoleBasedReturnReasons = PartnerReturnReasons;
            break;
        case "VFU":
            RoleBasedReturnReasons = FieldReturnReasons;
            break;
        default:
            RoleBasedReturnReasons = OpsReturnReasons;

    }

    _returnReasons = _returnReasonsList && _returnReasonsList.length > 0 && _returnReasonsList.filter(function (n) {
        return RoleBasedReturnReasons.indexOf(n.AdjustmentReasonCode.trim()) !== -1;
    });
    var _reasonsForDropDown = [];
    _returnReasons && !isEmpty(_returnReasons) && _returnReasons.forEach((c) => {
        return _reasonsForDropDown.push({
            'key': c.AdjustmentReasonCode, 'value': c.AdjustmentReasonCode, "label": c.AdjustmentReasonName
        });
    });
    return _reasonsForDropDown;
}

function GetReturnReasonsForCPSDropDown(serviceDomainData, role, isOptOut) {
    var _returnReasons = [], _returnReasonsList = [];

    _returnReasonsList = DomainData.TotalReturnReasons;
    _returnReasonsList = sortByKeyAsc(_returnReasonsList, "AdjustmentReasonName");
    var RoleBasedReturnReasons = OpsCPSReturnReason;
    switch (role) {
        case "VSC":
        case "VPU":
            RoleBasedReturnReasons = PartnerReturnReasons;
            break;
        case "VFU":
            RoleBasedReturnReasons = FieldReturnReasons;
            break;
        default:
            RoleBasedReturnReasons = OpsCPSReturnReason;

    }

    _returnReasons = _returnReasonsList && _returnReasonsList.length > 0 && _returnReasonsList.filter(function (n) {
        return RoleBasedReturnReasons.indexOf(n.AdjustmentReasonCode.trim()) !== -1;
    });
    var _reasonsForDropDown = [];
    _returnReasons && !isEmpty(_returnReasons) && _returnReasons.forEach((c) => {

        if (c.AdjustmentReasonCode === 'YAB' && isOptOut) {
            return
        }

        return _reasonsForDropDown.push({
            'key': c.AdjustmentReasonCode, 'value': c.AdjustmentReasonCode, "label": c.AdjustmentReasonName
        });
    });
    return _reasonsForDropDown;
}


function getRejectionReasons() {
    var _rejectionReasonsForDropDown = [];
    RejectionReasons.forEach((c) => {
        return _rejectionReasonsForDropDown.push({
            'key': c.code, 'value': c.value, "label": c.emailContent
        });
    });
    return _rejectionReasonsForDropDown;
}

function getRejectionReasoByCode(code) {
    var _rejectionReason;
    RejectionReasons.forEach(function (item) {
        if (item.code === code) {
            _rejectionReason = item;
        }
    });
    return _rejectionReason;
}

function getFbcRejectionReasons() {
    var _fbcRejectionReasonsForDropDown = [];
    FbcRejectionReasons.forEach((c) => {
        return _fbcRejectionReasonsForDropDown.push({
            'key': c.code, 'value': c.value, "label": c.emailContent
        });
    });
    return _fbcRejectionReasonsForDropDown;
}


function getMTRejectionReasons() {
    var _MTRejectionReasonsForDropDown = [];
    MTRejectionReasons.forEach((c) => {
        return _MTRejectionReasonsForDropDown.push({
            'key': c.code, 'value': c.value, "label": c.emailContent
        });
    });
    return _MTRejectionReasonsForDropDown;
}


function getFbcRejectionReasoByCode(code) {
    var _fbcRejectionReason;
    FbcRejectionReasons.forEach(function (item) {
        if (item.code === code) {
            _fbcRejectionReason = item;
        }
    });
    return _fbcRejectionReason;
}

function getMTRejectionReasonByCode(code) {
    var _MTRejectionReason;
    MTRejectionReasons.forEach(function (item) {
        if (item.code === code) {
            _MTRejectionReason = item;
        }
    });
    return _MTRejectionReason;
}




function getCancelReasons() {
    var _cancelReasonsForDropDown = [];
    CancellationReasons.forEach((c) => {
        return _cancelReasonsForDropDown.push({
            'key': c.code, 'value': c.value, "label": c.value
        });
    });
    return _cancelReasonsForDropDown;
}

function getCancelReasoByCode(code) {
    var _cancelReason;
    CancellationReasons.forEach(function (item) {
        if (item.code === code) {
            _cancelReason = item;
        }
    });
    return _cancelReason;
}

function GetRocOverrideValue(code, prevcode) {
    var _overridelabel;
    code = (code) ? code : prevcode;
    OPS_CENTER.forEach(function (item) {
        if (item.value === code) {
            _overridelabel = item.label;
        }
    });
    return _overridelabel;
}


function GetBusinessDayCount(dDate1, dDate2) { // input given as Date objects
    var iWeeks, iDateDiff, iAdjust = 0;
    if (dDate2 < dDate1) return -1; // error code if dates transposed
    var iWeekday1 = dDate1.getDay(); // day of week
    var iWeekday2 = dDate2.getDay();
    iWeekday1 = (iWeekday1 === 0) ? 7 : iWeekday1; // change Sunday from 0 to 7
    iWeekday2 = (iWeekday2 === 0) ? 7 : iWeekday2;
    if ((iWeekday1 > 5) && (iWeekday2 > 5)) iAdjust = 1; // adjustment if both days on weekend
    iWeekday1 = (iWeekday1 > 5) ? 5 : iWeekday1; // only count weekdays
    iWeekday2 = (iWeekday2 > 5) ? 5 : iWeekday2;

    // calculate differnece in weeks (1000mS * 60sec * 60min * 24hrs * 7 days = 604800000)
    iWeeks = Math.floor((dDate2.getTime() - dDate1.getTime()) / 604800000);

    if (iWeekday1 <= iWeekday2) {
        iDateDiff = (iWeeks * 5) + (iWeekday2 - iWeekday1);
    } else {
        iDateDiff = ((iWeeks + 1) * 5) - (iWeekday1 - iWeekday2);
    }

    iDateDiff -= iAdjust // take into account both days on weekend

    return iDateDiff; // add 1 because dates are inclusive
}


function GetSubCFOStatusByCode(Code) {
    var result = DomainData.SubCFORequestStatusForGrid.filter(function (c) {
        return c.key === Code;
    });
    if (result.length) {
        return result[0].value;
    } else {
        return Code;
    }
}

function GetCosmicCaseTicketsAsString(tickets) {
    var strTickets = "";
    if (tickets && tickets !== "") {
        for (var i = 0; i < tickets.length; i++)
            strTickets += tickets[i].ticketId + ",";
    }
    return strTickets;
}
function GetReBillPOAsString(rebillPos) {
    var strRebillPos = "";
    if (rebillPos && rebillPos !== "") {
        for (var i = 0; i < rebillPos.length; i++)
            strRebillPos += rebillPos[i].systemPONumber + ",";
    }
    return strRebillPos;
}


function KeepFocusInPopUp(divID) {

    if (divID) {
        var p = document.getElementById(divID);
        if (p) {
            var focusableItems = p.querySelectorAll('input,a,select,button');
            var firstFocusableEl = focusableItems[0];
            var lastFocusableEl = focusableItems[focusableItems.length - 1];
            if (focusableItems.length > 0) {
                firstFocusableEl.focus();
            }

            p.addEventListener('keydown', function (e) {
                var isTabPressed = (e.key === 'Tab' || e.keyCode === "9");
                if (!isTabPressed) {
                    return;
                }
                if (e.shiftKey && document.activeElement === firstFocusableEl) {
                    e.preventDefault();
                    lastFocusableEl.focus();
                } else if (!e.shiftKey && document.activeElement === lastFocusableEl) {
                    e.preventDefault();
                    firstFocusableEl.focus();
                }
            });
        } else {
            console.log("Elements not found")
        }
    }
}

function KeepFocusInOnedashPopUp(divID) {

    if (divID) {
        var p = document.getElementById(divID);
        if (p) {
            var focusableItems = p.querySelectorAll('button');
            var firstFocusableEl = focusableItems[0];
            var lastFocusableEl = focusableItems[focusableItems.length - 1];
            lastFocusableEl.focus();


            p.addEventListener('keydown', function (e) {
                var isTabPressed = (e.key === 'Tab' || e.keyCode === "9");
                if (!isTabPressed) {
                    return;
                }
                if (e.shiftKey && document.activeElement === firstFocusableEl) {
                    e.preventDefault();
                    lastFocusableEl.focus();
                } else if (!e.shiftKey && document.activeElement === lastFocusableEl) {
                    e.preventDefault();
                    firstFocusableEl.focus();
                }
            });
        } else {
            console.log("Elements not found")
        }
    }
}

function sortByKeyAsc(array, key) {
    return array.sort(function (a, b) {
        var x = a[key]; var y = b[key];
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
}

function AddErrorControl(divID, message) {
    var divElement = document.getElementById(divID);
    if (divElement) {
        divElement.setAttribute('class', "alert alert-error");
        divElement.setAttribute('aria-live', "assertive");
        divElement.innerHTML = message;
    }
    return divElement;
}

function IsInvoiceEligibleForCancel(invoice) {
    return invoice && invoice.requestStatusCode === "CP" && !invoice.isAutoSubmit && (isCancelledEnabled() === "True");
}

function IsROCOverriden() {
    return (isROCOverridenEnabled() === "True") ? true : false;
}

function IsallowedProgramCode(programCode) {
    var allowedProgroam = allowedProgramCode().split(",");
    var Isallowed = false;
    allowedProgroam.forEach(function (item) {
        if (item == programCode || item == "ALL") {
            Isallowed = true;
        }
    });
    return Isallowed;
}

function IsCPSCountryCodeAllowed(countryCode) {
    var BlockedProgram = BlockedCPSCountryCode().split(",");
    var IsBlocked = false;
    BlockedProgram.forEach(function (item) {
        if (item == countryCode || item == "ALL") {
            IsBlocked = true;
        }
    });
    return IsBlocked;
}

function fetchInvoiceNumbersFromObject(multipleInvoiceList) {
    const invoiceList = multipleInvoiceList.reduce((invoiceList, data) => {
        if (data.isSelected) {
            invoiceList.push(
                data.invoiceNumber
            );
        }
        return invoiceList;
    }, []);
    return invoiceList;
}

function checkRestockFee(multipleInvoiceList) {
    let isRestockFeeApplied = false;

    multipleInvoiceList.map((invoice) => {
        if (invoice.isSelected && invoice.restockFee === 5) {
            isRestockFeeApplied = true;
        }
    });

    return isRestockFeeApplied;
}

function performRebillCalculation(rebillPOList, action) {

    let totalRebillPOAmount = 0;
    let totalRebillPOAmountUSD = 0;
    let currencySet = new Set();
    let isRebillCurrencyDiff = false;

    rebillPOList.map(row => {
        let rebillPOAmount = 0;
        let rebillPOAmountUSD = 0;


        row.isEligible && currencySet.add(row.currency);

        action && row.lineItems && row.lineItems.map((invoice, key) => {
            if (invoice.uId === action.id) {
                invoice.isSelected = action.isSelected;
            }
        });

        if (row.lineItems) {
            row.lineItems.map((invoice, key) => {
                if (invoice.isSelected) {
                    rebillPOAmount = rebillPOAmount + invoice.amount;
                    rebillPOAmountUSD = rebillPOAmountUSD + invoice.amountUSD;
                }
            });
        }
        else {
            rebillPOAmount = row.amount;
            rebillPOAmountUSD = row.amountUSD;
        }

        row.rebillPOAmount = rebillPOAmount;
        row.rebillPOAmountUSD = rebillPOAmountUSD;
        row.selectedAmount = rebillPOAmount;
        row.selectedAmountUSD = rebillPOAmountUSD;

        if (row.isEligible) {
            totalRebillPOAmount = totalRebillPOAmount + row.rebillPOAmount;
            totalRebillPOAmountUSD = totalRebillPOAmountUSD + row.rebillPOAmountUSD;
        }
    });

    if (currencySet.size > 1) {
        totalRebillPOAmount = 0;
        isRebillCurrencyDiff = true;
    }

    return [rebillPOList, totalRebillPOAmount, totalRebillPOAmountUSD, isRebillCurrencyDiff];
}

function getMultiPleInvoices(multipleInvoiceList, param) {
    let invoiceList = [];


    invoiceList = multipleInvoiceList.reduce((invoiceList, data) => {
        if (data.isSelected) {
            invoiceList.push(
                param == "invoiceDate" ? DateFormatForDisplay(new Date(data[param])) : data[param]
            );
        }
        return invoiceList;
    }, []);


    return invoiceList.join('\r\n');
}

function GetFBCReturnReasonsForDropDown(role) {
    var _returnReasons = [], _returnReasonsList = [];
    var _returnFBCROCReturnReason = window && window.vlcredits && window.vlcredits.FBCROCReturnReasons;
    var _returnFBCPartnerReturnReason = window && window.vlcredits && window.vlcredits.FBCPartnerReturnReasons;
    _returnReasonsList = DomainData.TotalReturnReasons;
    _returnReasonsList = sortByKeyAsc(_returnReasonsList, "AdjustmentReasonName");
    var RoleBasedReturnReasons = _returnFBCROCReturnReason;
    switch (role) {
        case "VPU":
            RoleBasedReturnReasons = _returnFBCPartnerReturnReason;
            break;
        default:
            RoleBasedReturnReasons = _returnFBCROCReturnReason;

    }

    _returnReasons = _returnReasonsList && _returnReasonsList.length > 0 && _returnReasonsList.filter(function (n) {
        return RoleBasedReturnReasons.indexOf(n.AdjustmentReasonCode.trim()) !== -1;
    });
    var _reasonsForDropDown = [];
    _returnReasons && !isEmpty(_returnReasons) && _returnReasons.forEach((c) => {
        return _reasonsForDropDown.push({
            'key': c.AdjustmentReasonCode, 'value': c.AdjustmentReasonCode, "label": c.AdjustmentReasonName
        });
    });
    return _reasonsForDropDown;
}

function GetFBCCPSReturnReasonsForDropDown(role) {
    var _returnReasons = [], _returnReasonsList = [];
    var _returnFBCROCReturnReason = FBCCPSROCReturnReasons();
    var _returnFBCPartnerReturnReason = window && window.vlcredits && window.vlcredits.FBCPartnerReturnReasons;
    _returnReasonsList = DomainData.TotalReturnReasons;
    _returnReasonsList = sortByKeyAsc(_returnReasonsList, "AdjustmentReasonName");
    var RoleBasedReturnReasons = _returnFBCROCReturnReason;
    switch (role) {
        case "VPU":
            RoleBasedReturnReasons = _returnFBCPartnerReturnReason;
            break;
        default:
            RoleBasedReturnReasons = _returnFBCROCReturnReason;

    }

    _returnReasons = _returnReasonsList && _returnReasonsList.length > 0 && _returnReasonsList.filter(function (n) {
        return RoleBasedReturnReasons.indexOf(n.AdjustmentReasonCode.trim()) !== -1;
    });
    var _reasonsForDropDown = [];
    _returnReasons && !isEmpty(_returnReasons) && _returnReasons.forEach((c) => {
        return _reasonsForDropDown.push({
            'key': c.AdjustmentReasonCode, 'value': c.AdjustmentReasonCode, "label": c.AdjustmentReasonName
        });
    });
    return _reasonsForDropDown;
}


function DateSubStr(dateStr, length) {
    return dateStr ? dateStr.substring(0, length) : dateStr;
}

function IsIEBrowser() {
    const ua = window.navigator.userAgent; //Check the userAgent property of the window.navigator object
    const msie = ua.indexOf('MSIE '); // IE 10 or older
    const trident = ua.indexOf('Trident/'); //IE 11

    return (msie > 0 || trident > 0);
}

export {
    GroupBy, CalculateCoveragePeriod, sortInvoiceList, isINT, isFLOAT, ConvertToDecial, getFilteredColumns, getNextDays, ResetForm, IsInvoiceEligibleForCancel,
    GetCountryByCode, GetBillingOptionCode, GetPurchaseOrderNameByCode, GetPartnerStatusByCode, GetLineItemBySID,
    GetStatusByCode, GetProgramNameByCode, GetCurrencyNameByCode, DateFormat, GetCosmicCaseNumber, GetCountryListForDropDown,
    GetAdjustedLineItems, GetCountriesFromDomain, GetBillingOptionsFromDomain, GetProgramsFromDomain, GetProgramCodesForDropDown, GetCurrencyListFromDomain,
    GetPurchaseOrderNameFromDomain, isEmpty, FilterButtonDaysList, CUSTOMDATE, DateFormatForDisplay, DateFormatSearch, GetReturnReasonsFromDomain,
    GetReturnReasonsForDropDown, GetReturnReasonsByCode, GetReturnReasonsByCodeNew, GetBusinessDayCount, GetSubCFOStatusByCode, GetCosmicCaseTicketsAsString,
    GetReBillPOAsString, KeepFocusInPopUp, ToFixedTruncate, CurencyFormatter, GetSalesLocationByCode, getRejectionReasons, getRejectionReasoByCode, AddErrorControl, getCancelReasons, getCancelReasoByCode, fetchInvoiceNumbersFromObject,
    GetRocOverrideValue, IsROCOverriden, IsallowedProgramCode, performRebillCalculation,
    checkRestockFee, FormatInputDateFromDateRangeAction, getMultiPleInvoices, KeepFocusInOnedashPopUp, isEmptyOrSpaces,

    GetFBCReturnReasonsForDropDown, GetFbcOpsStatusByCode, GetFbcPartnerStatusByCode, DateSubStr, sortByKeyAsc, IsIEBrowser, getFbcRejectionReasons, getFbcRejectionReasoByCode, getMTRejectionReasons,getMTRejectionReasonByCode,GetReturnReasonsForCPSDropDown, IsCPSCountryCodeAllowed, GetFBCCPSReturnReasonsForDropDown, GetMTPartnerRequestStatusByCode, GetMTOpsRequestStatusByCode
};



export const userRoleType = {
    'VPU': 'Partner',
    'VCA': 'CreditApprover',
    'VCS': 'CreditSubmitter',
    'VSC': 'SubsidiaryCFO',
    'VFU': 'FieldUser',
    'VOU': 'OpsUser',
    'VAU': 'VlActiveUser'
}

export const Submitter = {
    'VPU': 'Partner',
    'VCS': 'BPO Agent',
    'VFU': 'Field',

}


export const FBCSubmitter = {
    'VPU': 'Partner',
    'VCA': 'BPO Agent',
    'VCS': 'BPO Agent',
    'VSC': 'BPO Agent',
    'VFU': 'BPO Agent',
    'VOU': 'BPO Agent',
    'VAU': 'BPO Agent',
    "External": "Partner",
    "Internal": "BPO Agent"
}