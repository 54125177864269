import React from "react";
import { injectIntl } from "react-intl";
import { Switch, Route } from "react-router-dom";
import '../../app/accessibility-style.css';

function CancelFutureBillingMenu(props) {
    //constants
    const { formatMessage } = props.intl;
    var lblFutureBillingsCancellationText = formatMessage({
        id: "lblFutureBillingsCancellation",
    });
    var lblLicenseReductionText = formatMessage({ id: "lblLicenseReduction" });
    var lblSearchText = formatMessage({ id: "lblSearchText" });

    var lnklblLRMenuItemTitle = formatMessage({ id: "lnklblLRMenuItemTitle" });

    const userRole = window && window.userRole;

    var _createLink = userRole === "VPU" ? "partner/fbc/create" : "/fbc/create";
    var _searchLink = userRole === "VPU" ? "partner/fbc/search" : "/fbc/search";

    //return elements
    return (
        <div>
            <div>
                <div
                    id="license-reduction-name"
                    className="onedash-accountpicker-account is-visible"
                >
                    {/* Display label text based on the user role. 
              If VPU role - 'License Reduction' 
              else 'Future Billings Cancellation' */}
                    <span
                        className="activeAccount activeAccountTitle"
                        aria-label={lblFutureBillingsCancellationText}
                    >
                        {userRole === "VPU"
                            ? lblLicenseReductionText
                            : lblFutureBillingsCancellationText}
                    </span>
                </div>
                <div
                    id="onedash-internaltools-menu-title-2"
                    className="onedash-navigation-menu-title is-active"
                >
                    <ul role="menu">
                        <li className="onedash-navigation-menu-item" role="none">
                            <a href={_createLink} id="linkFbcCreate" role="menuitem">
                                <div
                                    className="onedash-navigation-category"
                                    aria-label={lblLicenseReductionText + lnklblLRMenuItemTitle}
                                >
                                    {lnklblLRMenuItemTitle}
                                </div>
                            </a>
                        </li>
                        <li className="onedash-navigation-menu-item" role="none">
                            <a href={_searchLink} id="linkFbcSearch" role="menuitem">
                                <div
                                    className="onedash-navigation-category"
                                    aria-label={lblLicenseReductionText + lblSearchText}
                                >
                                    {lblSearchText}
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}
export default injectIntl(CancelFutureBillingMenu);
